import axios from "./axios";

import {
    API_BASE_URL,
} from '../../env';

// import { ElMessageBox } from "element-plus";


class UserService {
    getURL() {
        return API_BASE_URL + "/";
    }
    //#region Dashboard
    DashboardAllData(request) {
        let body = request == null ? {} : request;
        let response = axios.post("api/dashboard-all-data", body);
        return response;
    }

    DashboardHomeData(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            state: request.state
        };
        let response = axios.post("api/highlights", body);
        return response;
    }

    DashboardSessionsData(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            group_by: request.group_by,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            state: request.state
        };
        let response = axios.post("api/dashboard-session-data", body);
        return response;
    }

    // GetAllChargingSite(request, page, page_size = undefined) {
    //     let body = {
    //         customer_id: request.customer_id,
    //         search: request.search,
    //         state: request.state,
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/all-charging-sites?page=" + params, body);
    //     return response;
    // }

    GetAllChargingSite(request, page, page_size = undefined, no_page = false) {
        let body = {
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // if (optional !== undefined) {
        //     body.optional_request = optional;
        // }
        // console.log("all charging site response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/all-charging-sites${queryString}`, body);
        return response;
    }


    GetThreeInOneChargerData(request){
        let body = {
            customer_id : request.customer_id
        }
        let response = axios.post(`api/get-three-in-one`, body);
        // console.log("get three in one charger", response);
        
        return response;
    }

    //#region Dashboard Estimator
    // DashboardEstimator start
    DashboardEstimator(request) {

        if (!request) {
            console.error("Invalid request object:", request);
            return;
        }
        // console.log("Request body:", JSON.stringify(request));
        return axios.post("api/estimator/", request);
    }

    DashboardEstimatorFilter() {
        console.log("Getting filter data...");
        return axios.get("api/filterbylocation/");
    }

    // DashboardEstimator end

    // Dashboard auditor-reports start

    //#region Auditor Reports
    

 
            // request without pagination
            // DashboardAuditorReports(request) {
            //     if (!request) {
            //         console.error("Invalid request object:", request);
            //         return;
            //     }

    //     let response = axios.post("api/auditor-reports", request);
    //     console.log("service received Request:", JSON.stringify(request));
    //     return response;
    // }

    // request with pagination
    // DashboardAuditorReports(request) {
    //     if (!request) {
    //         console.error("Invalid request object:", request);
    //         return Promise.reject(new Error("Request object is invalid"));
    //     }

    //     let page = request.current_page;
    //     let page_size = request.perPageCount;
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    
    //     try {
    //         let response = axios.post("api/auditor-reports?page=" + params, request);
    //         return response;
    //     } catch (error) {

    //         return Promise.reject(error); // Propagate the error for further handling
    //     }
    // }

    DashboardAuditorReports(request) {
        if (!request) {
            console.error("Invalid request object:", request);
            return Promise.reject(new Error("Request object is invalid"));
        }
    
        let page = request.current_page;
        let page_size = request.perPageCount;
        let no_page = request.no_page || undefined;
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        if (no_page) {
            params += '&no_page=true'; // Bypass pagination
        }
    
        try {
            let response = axios.post("api/auditor-reports?page=" + params, request);
            // console.log("service received Request:", params, JSON.stringify(request));
            
            return response;
        } catch (error) {
            return Promise.reject(error); // Propagate the error for further handling
        }
    }



    // getProjectInstance(request, search, page, page_size = undefined) {
    //     let body = {
    //         search: search
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/project-instances?page=" + params, body);
    //     return response;
    // }

    getProjectInstance(request, search, page, page_size = undefined, no_page = false) {
        let body = {
            search: search
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // console.log("project instance response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/project-instances${queryString}`, body);
        return response;
    }

    DashboardAuditorReportsTest() {

        return axios.get("api/auditor-reports");
    }

    // Dashboard auditor-reports end
    //#endregion

    //#region Snapshot
    // auditor reports snapshot
    // DashboardAuditorReportsTestSnapshot(){
    //     let response = axios.get("api/snapshot-auditor-report");
    //     return response;
    // }

    SnapshotReport(request){
        let requestData = {

            log_date: request.log_date,
            form_kind: request.form_kind,
            action_kind: request.action_kind,
            document_id: request.document_id,
            comment: request.comment,
            before_change: request.before_change,
        }

        console.log("Snapshot request data:", requestData);
        

        if(requestData.before_change.length === 0){
            return Promise.resolve({
                success: false,
                message: `Snapshot request not success! ${requestData.comment} is empty!`,
            });
            
        } else {
            // let response = axios.post("api/snapshot-report", requestData);
            // return response;
            return axios.post("api/snapshot-report", requestData).then((response) => {
               
                return {
                    success: true,
                    message: `Snapshot request success! ${requestData.comment} created successfully!`,
                    response: response,
                }
            }).catch((error) => {
                console.error("Error while creating snapshot report:", error);
                
                return {
                    success: false,
                    message: `Snapshot request not success! ${requestData.comment} not successfully!`,
                    error: error,
                }
            });
        }

    }

    getSnapshotList(page, filter, page_size = undefined) {
        let body = filter
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/snapshot-list?page=" + params, body);
        return response;
    }

    // auditor reports snapshot end
    //#endregion


    DashboardSessionGroupBy(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            kind: request.kind,
            group_by: request.group_by,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            state: request.state,
            search: request.search,
        };
        let response = axios.post("api/dashboard-session-group-by", body);
        return response;
    }


    // DashboardCharger get xlsx file
    DashboardChargersXlsx() {

        let response = axios.post("api/export-chargerschedule-excel", {}, {
            responseType: 'blob'
        });
        return response;

    }

    // Charger Anomally get duplicate_of_id for charger site map
    DashboardChargerMapDuplicate() {
        let response = axios.get("api/get-charger-anomaly");
        return response;
    }

    DashboardChargersData(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            state: request.state,
        };
        let response = axios.post("api/dashboard-chargers-data", body);
        return response;
    }

    ChargersDistributions(request) {
        let body = {
            customer_id: request.customer_id,
            state: request.state,
        };
        let response = axios.post("api/charger-distributions", body);
        return response;
    }

    // GetAllCharger(request, page, page_size = undefined) {
    //     let body = {
    //         customer_id: request.customer_id,
    //         search: request.search,
    //         state: request.state,
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/all-chargers?page=" + params, body);
    //     return response;
    // }

    GetAllCharger(request, page, page_size = undefined, no_page = false) {
        let body = {
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // console.log("all charger response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/all-chargers${queryString}`, body);
        return response;
    }

    GetChargerSelection(request) {
        let body = {
            customer_id: request.customer_id,
            search: request.search
        };
        let response = axios.post("api/charger-list-selection", body);
        return response;
    }

    // GetAllPort(request, page, page_size = undefined) {
    //     let body = {
    //         customer_id: request.customer_id,
    //         search: request.search,
    //         state: request.state,
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/all-ports?page=" + params, body);
    //     return response;
    // }

    GetAllPort(request, page, page_size = undefined, no_page = false) {
        let body = {
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // if (optional !== undefined) {
        //     body.optional_request = optional;
        // }
        // console.log("all port response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        console.log("body:", body);
        
        let response = axios.post(`api/all-ports${queryString}`, body);
        return response;
    }

    GetChargerList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
            charging_site_id: request.charging_site_id,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/charger-list?page=" + params, body);
        return response;
    }

    GetTopPerformingSites(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            state: request.state,
            start_date: request.start_date,
            end_date: request.end_date,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/top-performing-sites?page=" + params, body);
        return response;
    }

    GetSessionList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            search: request.search,
            state: request.state,
        };
        if (page_size != 0) {
            let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
            let response = axios.post("api/session-list?page=" + params, body);
            return response;

        } else {
            let response = axios.post("api/session-list?bypass_pagination=true", body);
            return response;

        }
    }

    GetPendingSessionList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            search: request.search,
            kind: request.kind,
            state: request.state
        };

        if (page_size != 0) {
            let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
            let response = axios.post("api/session-log-list?page=" + params, body);
            return response;

        } else {
            let response = axios.post("api/session-log-list?bypass_pagination=true", body);
            return response;

        }
    }

    GetAbatementHistory(request) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
            state: request.state,
        };
        let response = axios.post("api/abatement-history", body);
        return response;
    }



    // eslint-disable-next-line no-unused-vars
    GetLiveAbatementList(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
            search: request.search,
            state: request.state,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/get_live_abt?page=" + params, body);
        return response;
    }


    GetCompareDailyEmissions(request) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
        };
        // let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/compare-daily-emissions", body);
        return response;
    }



    // eslint-disable-next-line no-unused-vars
    GetAllInOne(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
            search: request.search,
        };

        console.log("all in one body:", JSON.stringify(body));
        console.log("all in one request:", JSON.stringify(request));
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/all-in-one?page=" + params, body);
        return response;
    }

    // eslint-disable-next-line no-unused-vars
    GetInvalidSessionList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            search: request.search,
            customer_id: request.customer_id,
        };
        let response = axios.post("api/dashboard-session-count", body);
        return response;
    }

    GetSessionTimeOfDayList(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            by: request.by,
            customer_id: request.customer_id,
            group_by_item: request.group_by_item,
            count: request.count,
        };
        // console.log(body)
        let response = axios.post("api/dashboard-session-Time-of-day-count", body);
        return response;
    }

    GetOperationsGrowthList(request) {
        let body = {
            by: request.by,
            customer_id: request.customer_id,
            count: request.count,
        };
        let response = axios.post("api/operation-growth-trends", body);
        return response;
    }

    GetInfrastructureGrowthList(request) {
        let body = {
            by: request.by,
            customer_id: request.customer_id,
            count: request.count,
        };
        let response = axios.post("api/infrastructure-growth-trends", body);
        return response;
    }

    //#endregion

    //#region Charger
    setChargers(request) {
        let body = {
            by_panel: true,
            content: request
        };
        let response = axios.post("api/upload-excel-json", body);
        return response;
    }
    setSimpleChargerUpload(request) {
        let body = request;
        let response = axios.post("api/simple-upload-charger-file", body);
        return response;
    }
    //#endregion

    //#region Session
    setSessions(request) {
        let body = {
            by_panel: true,
            content: request
        };
        let response = axios.post("api/upload-session-log-json", body);
        return response;
    }
    setSimpleSessionUpload(request) {
        let body = request;
        let response = axios.post("api/simple-upload-session-file", body);
        return response;
    }

    uploadSessionFile(request) {
        let body = request;
        let response = axios.post("api/upload_file", body);
        return response;
    }

    getSummerySessionLogByError(request, page, page_size = undefined) {
        let body = request;
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/summery-session-log-by-error-list?page=" + params, body);
        return response;
    }

    sessionLogRemoveBy(request) {
        let body = request;
        let response = axios.post("api/session-log-remove-by", body);
        return response;
    }

    AbatementCalculate(request) {
        let body = {
            customer_id: request.customer_id
        };
        let response = axios.post("api/abatement-calculator", body);
        return response;
    }

    // delete session
    GetUniqueChargerId(request) {
        let body = {
            charging_site_id: request.charger_site_id,
            charger_name : request.charger_name
        };

        let response = axios.post("api/get-unique-charger-id", body);
        return response;
    }

    // delete session by unique charger id
    DeleteSessionsByUniqueChargerId(request) {
        let body = {
            type: request.type,
            charger_id: request.uniq_charger_id,
            start_date: request.startDate,
            end_date: request.endDate
        };

        console.log("DeleteSessionsByUniqueChargerId body:", body);
        

        let response = axios.post("api/delete-all-sessions", body);
        // let response = {"delete submiteed": body};
        return response;
        
    }

    //#endregion

    //#region Support
    getCustomerSupport(request, filter, page, page_size = undefined) {
        let body = {};
        if (request != null)
            body = {
                id: request
            };
        else {
            body = {
                from_date: filter.fromDate,
                to_date: filter.toDate,
                status_row: filter.statusRow,
                search: filter.search,
            };
        }
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/customer-supports?page=" + params, body);
        return response;
    }

    SetSupport(request) {
        let body = request;
        let response = axios.post("api/create-support", body);
        return response;
    }

    SetSupportDetail(request) {
        let body = request;
        let response = axios.post("api/create-support-detail", body);
        return response;
    }

    getAdminSupport(request, filter, page, page_size = undefined) {
        let body = {};
        if (request != null)
            body = {
                id: request
            };
        else {
            body = {
                from_date: filter.fromDate,
                to_date: filter.toDate,
                status_row: filter.statusRow,
                search: filter.search,
            };
        }
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/supports?page=" + params, body);
        return response;
    }
    //#endregion

    //#region User
    // eslint-disable-next-line no-unused-vars
    // getUsers(request, search, page, page_size = undefined) {
    //     let body = {
    //         search: search
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/users?page=" + params, body);
    //     return response;
    // }

    getUsers(request, search, page, page_size = undefined, no_page = false) {
        let body = {
            search: search
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // console.log("users response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/users${queryString}`, body);
        return response;
    }



    SetUser(request) {
        let body = request;
        let url = (request.id != 0 ? 'api/update-user' : 'api/create-user');
        let response = axios.post(url, body);
        return response;
    }

    deleteUser(request) {
        let body = {
            user_id: request
        };
        let response = axios.post("api/delete-user", body);
        return response;
    }

    //#endregion

    //#region Customer
    // eslint-disable-next-line no-unused-vars
    getCustomers(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/customers?page=" + params, body);
        return response;
    }

    SetCustomer(request) {
        let body = request;
        let url = 'api/save-customer';
        let response = axios.post(url, body);
        return response;
    }

    deleteCustomer(request) {
        let body = {
            customer_id: request
        };
        let response = axios.post("api/delete-customer", body);
        return response;
    }


    deleteCustomerAllData(request) {
        let body = {
            customer_id: request
        };
        let response = axios.post("api/delete-customer-all-data", body);
        return response;
    }

    //#endregion

    //#region ChargingSite
    getChargingSites(request, search, page, page_size = undefined, site_id = null) {
        let body = {
            search: search
        };
        if (request != null)
            body = {
                site_id: request.site_id,
                customer_id: request.customer_id
            };

        if (site_id != null) {
            body = {
                search: search,
                site_id: site_id
            };
        }
        //let response = axios.post("api/session-log-list?bypass_pagination=true", body);
        if (page_size != 0) {
            let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
            let response = axios.post("api/charging-site-list?page=" + params, body);
            return response;

        } else {
            let response = axios.post("api/charging-site-list?bypass_pagination=true", body);
            return response;

        }

    }

    updateChargingSite(request) {
        let body = request;
        let response = axios.post("api/charging-site-update", body);
        return response;
    }

    updateCharger(request) {
        let body = request;
        let response = axios.post("api/charger-update", body);
        return response;
    }

    updatePort(request) {
        let body = request;
        let response = axios.post("api/port-update", body);
        return response;
    }

    deleteChargingSite(request) {
        let body = {
            charging_site_id: request
        };
        let response = axios.post("api/delete-charging-sites", body);
        return response;
    }
    deleteCharger(request) {
        let body = {
            charger_id: request
        };
        console.log("body: ", body)
        let response = axios.post("api/delete-chargers", body);
        return response;
    }
    deleteChargerPort(request) {
        let body = {
            charger_port_id: request
        };
        console.log("body: ", body)
        let response = axios.post("api/delete-ports", body);
        return response;
    }
    //#endregion

    //#region Admin Session
    // eslint-disable-next-line no-unused-vars
    getSessions(request, page, page_size = undefined) {
        let body = request;
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/session-list-view?page=" + params, body);
        return response;
    }
    //#endregion

    //#region Baseline Params
    // eslint-disable-next-line no-unused-vars
    // getBaselineParams(request, search, page, page_size = undefined) {
    //     let body = {
    //         search: search
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/baseline-params?page=" + params, body);
    //     return response;
    // }

    getBaselineParams(request, search, page, page_size = undefined, no_page = false) {
        let body = {
            search: search
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // console.log("baseline params response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/baseline-params${queryString}`, body);
        return response;
    }

    SetBaselineParam(request) {
        let body = request;
        let url = 'api/save-baseline-param';
        let response = axios.post(url, body);
        return response;
    }

    deleteBaselineParam(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-baseline-param", body);
        return response;
    }

    //#endregion

    //#region SiteBaseline Params
    // eslint-disable-next-line no-unused-vars

    // getSiteBaselineParams(request, search, page, page_size = undefined) {
    //     let body = {
    //         search: search
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/site-baseline-params?page=" + params, body);
    //     return response;
    // }

    getSiteBaselineParams(request, search, page, page_size = undefined, no_page = false) {
        let body = {
            search: search
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // console.log("site baseline params response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/site-baseline-params${queryString}`, body);
        return response;
    }



    SetSiteBaselineParam(request) {
        let body = request;
        let url = 'api/save-site-baseline-param';
        let response = axios.post(url, body);
        return response;
    }

    deleteSiteBaselineParam(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-site-baseline-param", body);
        return response;
    }

    //#endregion

    //#region Participants
    getParticipants(page, search, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/participants?page=" + params, body);
        return response;
    }

    SetParticipant(request) {
        let body = request;
        let url = 'api/save-participant';
        let response = axios.post(url, body);
        return response;
    }

    deleteParticipant(request) {
        let body = {
            duid: request
        };
        let response = axios.post("api/delete-participant", body);
        return response;
    }

    //#endregion

    //#region History Log
    // eslint-disable-next-line no-unused-vars
    getHistoryLogs(page, filter, page_size = undefined) {
        let body = filter
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/history-logs?page=" + params, body);
        return response;
    }

    //#endregion

    //#region Fleet
    setFleet(request) {
        let body = {
            by_panel: true,
            content: request
        };
        let response = axios.post("api/upload-vehicle", body);
        return response;
    }
    //#endregion

    //#region On Site Renewable Params
    // eslint-disable-next-line no-unused-vars
    getOnSiteRenewable(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/on-site-renewable?page=" + params, body);
        return response;
    }

    SetOnSiteRenewable(request) {
        let body = request;
        let url = 'api/save-on-site-renewable';
        let response = axios.post(url, body);
        return response;
    }

    deleteOnSiteRenewable(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-on-site-renewable", body);
        return response;
    }

    //#endregion

    //#region MonitoringPeriods
    // eslint-disable-next-line no-unused-vars
    // getMonitoringPeriods(request, search, page, page_size = undefined) {
    //     let body = {
    //         search: search
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/monitoring-periods?page=" + params, body);
    //     return response;
    // }

    getMonitoringPeriods(request, search, page, page_size = undefined, no_page = false) {
        let body = {
            search: search
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // console.log("monitoring periods response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/monitoring-periods${queryString}`, body);
        return response;
    }

    SetMonitoringPeriod(request) {
        let body = request;
        let url = 'api/save-monitoring-periods';
        let response = axios.post(url, body);
        return response;
    }

    DeleteMonitoringPeriod(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-monitoring-periods", body);
        return response;
    }

    //#endregion

    //#region Battery Intensities
    // eslint-disable-next-line no-unused-vars
    // getBatteryIntensities(request, search, page, page_size = undefined) {
    //     let body = {
    //         search: search
    //     };
    //     let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
    //     let response = axios.post("api/battery-intensities?page=" + params, body);
    //     return response;
    // }

    getBatteryIntensities(request, search, page, page_size = undefined, no_page = false) {
        let body = {
            search: search
        };
        let params = [];
        if (page == undefined) {
            params.push('page=1');
        }
        if (page !== undefined) {
            params.push(`page=${page}`);
        }
        if (page_size !== undefined) {
            params.push(`page_size=${page_size}`);
        }
        if (no_page) {
            params.push('no_page=true'); // Bypass pagination
        }
        // console.log("battery intensities response:", params);
        let queryString = params.length > 0 ? '?' + params.join('&') : '';
        let response = axios.post(`api/battery-intensities${queryString}`, body);
        return response;
    }

    SetBatteryIntensities(request) {
        let body = request;
        let url = 'api/save-battery-intensity';
        let response = axios.post(url, body);
        return response;
    }

    DeleteBatteryIntensities(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-battery-intensity", body);
        return response;
    }

    //#endregion

    //#region Vehicles
    // eslint-disable-next-line no-unused-vars
    getVehicles(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/vehicles?page=" + params, body);
        return response;
    }

    SetVehicles(request) {
        let body = request;
        let url = 'api/save-vehicle';
        let response = axios.post(url, body);
        return response;
    }

    DeleteVehicles(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-vehicle", body);
        return response;
    }

    //#endregion





}

export default new UserService();