<template>
  <el-row class="m-0 width-100-p mt-3">
    <el-col class="d-flex align-item-center px-2" :span="24">
      <div class="row m-0 width-100-p px-2">
        <div
          class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
        >
          <el-col class="mb-2" :span="24">
            <div class="row m-0">
              <el-col
                :span="
                  $vuetify.display.width >= 1400
                    ? 10
                    : $vuetify.display.width < 630
                    ? 24
                    : 16
                "
                :class="$vuetify.display.width < 630 ? 'x-center' : ''"
              >
                <el-radio-group
                  v-model="chargerFilter"
                  @change="selectChargerFilter"
                  text-color="#ffff"
                  fill="#234B49"
                  :class="$vuetify.display.width < 630 ? 'width-100-p' : ''"
                >
                  <el-radio-button
                    class="start-period all-group-by text-center"
                    label="1"
                    :class="$vuetify.display.width < 630 ? 'width-25-p' : ''"
                  >
                    All
                  </el-radio-button>
                  <el-radio-button
                    class="site-group-by text-center"
                    label="2"
                    :class="$vuetify.display.width < 750 ? 'width-20-p' : ''"
                  >
                    Charging Site
                  </el-radio-button>
                  <el-radio-button
                    class="charger-group-by text-center"
                    label="3"
                    :class="$vuetify.display.width < 750 ? 'width-20-p' : ''"
                  >
                    Chargers
                  </el-radio-button>
                  <el-radio-button
                    class="port-group-by text-center end-period"
                    label="4"
                    :class="$vuetify.display.width < 750 ? 'width-20-p' : ''"
                  >
                    Ports
                  </el-radio-button>
                </el-radio-group>

                <!-- download excel button -->
                <el-button
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.is_admin
                  "
                  @click="downloadChargersXlsx"
                  color="#0D3937"
                  class="height-40 border-color-light rounded-3 mt-2 ml-5"
                  :class="$vuetify.display.width < 750 ? 'width-20-p' : ''"
                >
                  <i class="text-white fa fa-cloud-download"></i>
                </el-button>

                
              </el-col>

              <!-- add new button here -->

              <el-col
                :span="
                  $vuetify.display.width >= 1400
                    ? 12
                    : $vuetify.display.width < 750
                    ? 24
                    : 8
                "
                class="d-flex align-item-center"
                :class="
                  $vuetify.display.width < 630 ? 'x-center mt-2' : 'justify-end'
                "
              >
                <div class="m-0" v-if="$vuetify.display.width >= 1400">
                  <el-input
                    size="large"
                    v-model="search"
                    style="width: 350px"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="mr-2 table-search"
                  />
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleRefresh"
                    class="height-40 rounded-3 mr-1"
                  >
                    <svg class="stroke-icon width-20 height-20">
                      <use
                        href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                      ></use>
                    </svg>
                    <span class="me-primary pl-3"> Apply </span>
                  </el-button>
                  <!-- <el-button
                    color="#0D3937"
                    class="height-40 border-color-light rounded-3 ml-1"
                  >
                    <svg class="stroke-icon width-20 height-20">
                      <use
                        href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                      ></use>
                    </svg>
                    <span class="text-white pl-3"> Filter </span>
                  </el-button> -->
                </div>
                <el-row class="m-0 p-0 width-100-p x-end" v-else>
                  <el-col
                    :span="$vuetify.display.width < 630 ? 24 : 24"
                    class="px-1 width-100-p"
                  >
                    <el-button
                      color="#ddf0ac"
                      @click.stop="handleRefresh"
                      class="height-40 rounded-3 width-100-p x-y-center"
                    >
                      <svg class="stroke-icon width-20 height-20">
                        <use
                          href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                        ></use>
                      </svg>
                      <span class="me-primary pl-3"> Apply </span>
                    </el-button>
                  </el-col>
                  <!-- <el-col
                    :span="$vuetify.display.width < 750 ? 12 : 12"
                    class="mt-2 px-1 width-100-p"
                  >
                    <el-button
                      color="#0D3937"
                      class="x-y-center height-40 border-color-light rounded-3 width-100-p"
                    >
                      <svg class="stroke-icon width-20 height-20">
                        <use
                          href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                        ></use>
                      </svg>
                      <span class="text-white pl-3"> Filter </span>
                    </el-button>
                  </el-col> -->
                  <el-col
                    v-if="$vuetify.display.width < 630"
                    :span="24"
                    class="width-100-p mt-4"
                  >
                    <el-input
                      class="table-search"
                      v-model="search"
                      size="large"
                      style="width: 100%"
                      placeholder="Type to search by site name, charger ID, port ..."
                      :prefix-icon="Search"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col
                v-if="
                  $vuetify.display.width >= 630 && $vuetify.display.width < 1400
                "
                :span="24"
                class="width-100-p mt-2"
              >
                <el-input
                  class="table-search"
                  v-model="search"
                  size="large"
                  style="width: 100%"
                  placeholder="Type to search by site name, charger ID, port ..."
                  :prefix-icon="Search"
                />
              </el-col>
            </div>
          </el-col>
          <el-col :span="24">
            <div
              class="ma-0"
              v-loading="loading"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              element-loading-background="rgba(122, 122, 122, 0.8)"
            >
              <!-- All List -->
              <el-table
                v-if="chargerFilter == 1"
                :data="AllDataList"
                :fit="true"
                height="300"
                class="my-2 data-table"
                scrollbar-always-on
              >
                <el-table-column
                  :label="'Customer'"
                  :prop="'customer'"
                  :width="$vuetify.display.width <= 2600 ? 200 : ''"
                  sortable
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.is_admin
                  "
                />
                <el-table-column
                  :label="'Site Name'"
                  :prop="'site_name'"
                  :width="$vuetify.display.width <= 2600 ? 200 : ''"
                  sortable
                  v-if="chargerFilter"
                />
                <el-table-column
                  :label="'Charger ID'"
                  :prop="'charger_name'"
                  :width="$vuetify.display.width <= 2600 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Port ID'"
                  :prop="'port_id'"
                  :width="$vuetify.display.width <= 2600 ? 150 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Postcode'"
                  :prop="'postcode'"
                  :width="$vuetify.display.width <= 2600 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Operational Date'"
                  :prop="'operational_date'"
                  :width="$vuetify.display.width <= 2600 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Model'"
                  :prop="'model'"
                  :width="$vuetify.display.width <= 2600 ? 170 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Make'"
                  :prop="'make'"
                  :width="$vuetify.display.width <= 2600 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Charger Type'"
                  :prop="'charger_type'"
                  :width="$vuetify.display.width <= 2600 ? 150 : ''"
                  sortable
                >
                  <template #default="scope">
                    <el-tag
                      :type="
                        scope.row.charger_type.toString().toUpperCase() === 'AC'
                          ? 'success'
                          : 'danger'
                      "
                      disable-transitions
                    >
                      {{ scope.row.charger_type.toString().toUpperCase() }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'On-Site Renewable'"
                  :prop="'onsite_renewable'"
                  :width="$vuetify.display.width <= 2600 ? 180 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.onsite_renewable.toString().toUpperCase() ==
                          'TRUE' ||
                        scope.row.onsite_renewable.toString().toUpperCase() ==
                          'YES'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Battery'"
                  :prop="'onsite_battery'"
                  :width="$vuetify.display.width <= 2600 ? 170 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.onsite_battery.toString().toUpperCase() ==
                          'TRUE' ||
                        scope.row.onsite_battery.toString().toUpperCase() ==
                          'YES'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Charger Capacity (kW)'"
                  :prop="'charger_capacity_kW'"
                  :width="$vuetify.display.width <= 2600 ? 190 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Port Type'"
                  :prop="'port_type'"
                  :width="$vuetify.display.width <= 2600 ? 150 : ''"
                  sortable
                />
              </el-table>
              <!-- Charging Site -->
              <el-table
                v-else-if="chargerFilter == 2"
                :data="SiteDataList"
                :fit="true"
                height="300"
                class="my-2 data-table"
                scrollbar-always-on
              >
                <el-table-column
                  :label="'Customer'"
                  :prop="'customer'"
                  :width="$vuetify.display.width <= 1800 ? 200 : ''"
                  sortable
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.is_admin
                  "
                />
                <el-table-column
                  :label="'Site Name'"
                  :prop="'site_name'"
                  :width="$vuetify.display.width <= 1800 ? 200 : ''"
                  sortable
                  v-if="chargerFilter"
                />
                <el-table-column
                  :label="'Country'"
                  :prop="'country'"
                  :width="$vuetify.display.width <= 1800 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'State'"
                  :prop="'state'"
                  :width="$vuetify.display.width <= 1800 ? 150 : ''"
                  sortable
                />
                <el-table-column
                  :label="'City'"
                  :prop="'city'"
                  :width="$vuetify.display.width <= 1800 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Suburb'"
                  :prop="'suburb'"
                  :width="$vuetify.display.width <= 1800 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Street'"
                  :prop="'street_name'"
                  :width="$vuetify.display.width <= 1800 ? 190 : ''"
                  sortable
                />

                <el-table-column
                  :label="'Address Comment'"
                  :prop="'address_comment'"
                  :width="$vuetify.display.width <= 1800 ? 350 : ''"
                  sortable
                />
              </el-table>
              <!-- Chargers -->
              <el-table
                v-else-if="chargerFilter == 3"
                :data="ChargerDataList"
                :fit="true"
                height="300"
                class="my-2 data-table"
                scrollbar-always-on
              >
                <el-table-column
                  :label="'Customer'"
                  :prop="'customer'"
                  :width="$vuetify.display.width <= 3000 ? 200 : ''"
                  sortable
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.is_admin
                  "
                />
                <el-table-column
                  :label="'Site Name'"
                  :prop="'site_name'"
                  :width="$vuetify.display.width <= 3000 ? 200 : ''"
                  sortable
                  v-if="chargerFilter"
                />
                <el-table-column
                  :label="'Charger ID'"
                  :prop="'charger_name'"
                  :width="$vuetify.display.width <= 3000 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Year Added'"
                  :prop="'year_added'"
                  :width="$vuetify.display.width <= 3000 ? 130 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Install Date'"
                  :prop="'install_date'"
                  :width="$vuetify.display.width <= 3000 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Operational Date'"
                  :prop="'operational_date'"
                  :width="$vuetify.display.width <= 3000 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Model'"
                  :prop="'model'"
                  :width="$vuetify.display.width <= 3000 ? 170 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Make'"
                  :prop="'make'"
                  :width="$vuetify.display.width <= 3000 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Charging Partner'"
                  :prop="'charging_partner'"
                  :width="$vuetify.display.width <= 3000 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Decommissioning Date'"
                  :prop="'decommissioning_date'"
                  :width="$vuetify.display.width <= 3000 ? 250 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Charger Type'"
                  :prop="'charger_type'"
                  :width="$vuetify.display.width <= 3000 ? 150 : ''"
                  sortable
                >
                  <template #default="scope">
                    <el-tag
                      :type="
                        scope.row.charger_type.toString().toUpperCase() === 'AC'
                          ? 'success'
                          : 'danger'
                      "
                      disable-transitions
                    >
                      {{ scope.row.charger_type.toString().toUpperCase() }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'On-Site Renewable'"
                  :prop="'onsite_renewable'"
                  :width="$vuetify.display.width <= 3000 ? 180 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.onsite_renewable.toString().toUpperCase() ==
                          'TRUE' ||
                        scope.row.onsite_renewable.toString().toUpperCase() ==
                          'YES'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Battery'"
                  :prop="'onsite_battery'"
                  :width="$vuetify.display.width <= 3000 ? 170 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.onsite_battery.toString().toUpperCase() ==
                          'TRUE' ||
                        scope.row.onsite_battery.toString().toUpperCase() ==
                          'YES'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Is Included'"
                  :prop="'is_included'"
                  :width="$vuetify.display.width <= 3000 ? 170 : ''"
                  sortable
                >
                  <template #default="scope">
                    <i
                      v-if="
                        scope.row.is_included.toString().toUpperCase() ==
                          'TRUE' ||
                        scope.row.is_included.toString().toUpperCase() == 'YES'
                      "
                      class="fa fa-check-square"
                    ></i>
                    <i v-else class="fa fa-square-o"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="'Charger Capacity (kW)'"
                  :prop="'charger_capacity_kW'"
                  :width="$vuetify.display.width <= 3000 ? 190 : ''"
                  sortable
                />
              </el-table>
              <!-- Charger Ports -->
              <el-table
                v-else-if="chargerFilter == 4"
                :data="PortDataList"
                :fit="true"
                height="300"
                class="my-2 data-table"
                scrollbar-always-on
              >
                <el-table-column
                  :label="'Customer'"
                  :prop="'customer'"
                  :width="$vuetify.display.width <= 1550 ? 200 : ''"
                  sortable
                  v-if="
                    $store.state.auth.user != null &&
                    $store.state.auth.user.is_admin
                  "
                />
                <el-table-column
                  :label="'Site Name'"
                  :prop="'site_name'"
                  :width="$vuetify.display.width <= 1550 ? 200 : ''"
                  sortable
                  v-if="chargerFilter"
                />
                <el-table-column
                  :label="'Charger ID'"
                  :prop="'charger_name'"
                  :width="$vuetify.display.width <= 1550 ? 200 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Port ID'"
                  :prop="'port_id'"
                  :width="$vuetify.display.width <= 1550 ? 150 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Port Type'"
                  :prop="'port_type'"
                  :width="$vuetify.display.width <= 1550 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Port Capacity KW'"
                  :prop="'port_capacity_kw'"
                  :width="$vuetify.display.width <= 1550 ? 180 : ''"
                  sortable
                />
                <el-table-column
                  :label="'Description'"
                  :prop="'description'"
                  :width="$vuetify.display.width <= 1550 ? 350 : ''"
                  sortable
                />
              </el-table>

              <!-- <div class="d-flex justify-center">
                <el-pagination
                  small
                  background
                  layout="prev, pager, next"
                  :total="total_items"
                  v-model:page-size="page_size"
                  v-model:current-page="current_page"
                  @current-change="changePage"
                  class="my-4"
                />
              </div> -->
              <el-row class="row-bg" justify="space-between">
                <el-col
                  :span="5"
                  class="d-flex justify-content-start align-items-center"
                >
                  <el-select
                    v-model="perPageCount"
                    placeholder="Select"
                    size="small"
                    style="width: 70px"
                  >
                    <el-option label="10" :value="'10'" />
                    <el-option label="25" :value="'25'" />
                    <el-option label="50" :value="'50'" />
                    <el-option label="100" :value="'100'" />
                  </el-select>
                </el-col>
                <el-col
                  :span="8"
                  class="d-flex align-items-center justify-content-center"
                >
                  <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    :total="total_items"
                    v-model:page-size="page_size"
                    v-model:current-page="current_page"
                    @current-change="changePage"
                    class="my-4"
                  />
                </el-col>
                <el-col
                  :span="5"
                  class="d-flex justify-content-end align-items-center"
                >
                  <span class="text-white">
                    {{ (current_page - 1) * perPageCount + 1 }}-{{
                      current_page * perPageCount > total_items
                        ? total_items
                        : current_page * perPageCount
                    }}
                    of {{ total_items }}
                  </span>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import userService from "../../../services/user.service";
import UserService from "../../../services/user.service";

// EXPORT xlsx button
import XLSX from "xlsx";

export default {
  props: {
    filterProp: {
      type: Object,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      AllDataList: [],
      SiteDataList: [],
      ChargerDataList: [],
      PortDataList: [],
      search: "",
      current_page: 1,
      page_size: 50,
      total_items: 100,
      loading: false,
      chargerFilter: 1,
      perPageCount: 50,
      filter: {
        customer_id: null,
        start_date: null,
        end_date: null,
        search: "",
        state: "",
      },

      
    };
  },
  watch: {
    filterProp: {
      handler: function (newVal) {
        this.filter = newVal;
        // this.fetchData(this.current_page, this.search);
        if (this.chargerFilter == 1) {
          this.fetchData(this.current_page, this.search);
        } else if (this.chargerFilter == 2) {
          this.fetchAllChargingSite(this.current_page, this.search);
        } else if (this.chargerFilter == 3) {
          this.fetchAllCharger(this.current_page, this.search);
        } else {
          this.fetchAllPort(this.current_page, this.search);
        }
      },
      deep: true,
    },

    current_page: function (newVal) {
      this.current_page = newVal;
      if (this.chargerFilter == 1) {
        this.fetchData(this.current_page, this.search);
      } else if (this.chargerFilter == 2) {
        this.fetchAllChargingSite(this.current_page, this.search);
      } else if (this.chargerFilter == 3) {
        this.fetchAllCharger(this.current_page, this.search);
      } else {
        this.fetchAllPort(this.current_page, this.search);
      }
    },
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      if (this.chargerFilter == 1) {
        this.fetchData(this.current_page, this.search);
      } else if (this.chargerFilter == 2) {
        this.fetchAllChargingSite(this.current_page, this.search);
      } else if (this.chargerFilter == 3) {
        this.fetchAllCharger(this.current_page, this.search);
      } else {
        this.fetchAllPort(this.current_page, this.search);
      }
    },
  },
  mounted() {

    // this.fetchData(this.current_page, this.search);
    if (this.chargerFilter == 1) {
      this.fetchData(this.current_page, this.search);
    } else if (this.chargerFilter == 2) {
      this.fetchAllChargingSite(this.current_page, this.search);
    } else if (this.chargerFilter == 3) {
      this.fetchAllCharger(this.current_page, this.search);
    } else {
      this.fetchAllPort(this.current_page, this.search);
    }
  },
  methods: {


    // export xslx file clients_data.xlsx
    async downloadChargersXlsx() {
      try {
        const response = await userService.DashboardChargersXlsx();

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "clients_data.xlsx";
        document.body.appendChild(link);
        link.click();

        // Cleanup
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (error) {
        console.error("error when download file", error);
      }
    },

    // export table data as xlsx file
    async handleExportFile() {
      if (this.chargerFilter == 1) {
        this.excelDataList = this.AllDataList;
      } else if (this.chargerFilter == 2) {
        this.excelDataList = this.SiteDataList;
      } else if (this.chargerFilter == 3) {
        this.excelDataList = this.ChargerDataList;
      } else {
        this.excelDataList = this.PortDataList;
      }

      // this.excelDataList = this.AllDataList;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");

        if (this.chargerFilter == 1) {
          XLSX.writeFileXLSX(wb, "AllDataList.xlsx");
        } else if (this.chargerFilter == 2) {
          XLSX.writeFileXLSX(wb, "SiteDataList.xlsx");
        } else if (this.chargerFilter == 3) {
          XLSX.writeFileXLSX(wb, "ChargerDataList.xlsx");
        } else {
          XLSX.writeFileXLSX(wb, "PortDataList.xlsx");
        }
      }
    },

    fetchData(page, search) {
      this.filter = {
        customer_id: this.filterProp.customer_id,
        start_date: this.filterProp.startDate,
        end_date: this.filterProp.endDate,
        search: search,
        state: this.filterProp.state == "All" ? "" : this.filterProp.state,
      };
      this.loading = true;
      UserService.GetChargerList(this.filter, page, this.perPageCount)
        .then(
          (response) => {
            this.total_items = response.data.total_record;
            this.AllDataList = response.data.chargers;
            console.log("GetChargerList", response.data);
            
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    fetchAllChargingSite(page, search) {
      let filter = {
        customer_id: this.filterProp.customer_id,
        search: search,
        state: this.filterProp.state == "All" ? "" : this.filterProp.state,
      };
      this.loading = true;
      UserService.GetAllChargingSite(filter, page, this.perPageCount)
        .then(
          (response) => {
            this.total_items = response.data.total_record;
            this.SiteDataList = response.data.charging_sites;


          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    fetchAllCharger(page, search) {
      let filter = {
        customer_id: this.filterProp.customer_id,
        search: search,
        state: this.filterProp.state == "All" ? "" : this.filterProp.state,
      };
      this.loading = true;
      UserService.GetAllCharger(filter, page, this.perPageCount)
        .then(
          (response) => {
            this.total_items = response.data.total_record;
            this.ChargerDataList = response.data.chargers;



          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    fetchAllPort(page, search) {
      let filter = {
        customer_id: this.filterProp.customer_id,
        search: search,
        state: this.filterProp.state == "All" ? "" : this.filterProp.state,
      };
      this.loading = true;
      UserService.GetAllPort(filter, page, this.perPageCount)
        .then(
          (response) => {
            this.total_items = response.data.total_record;
            this.PortDataList = response.data.charger_ports;

    

          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleRefresh() {
      this.current_page = 1;
      if (this.chargerFilter == 1) {
        this.fetchData(this.current_page, this.search);
      } else if (this.chargerFilter == 2) {
        this.fetchAllChargingSite(this.current_page, this.search);
      } else if (this.chargerFilter == 3) {
        this.fetchAllCharger(this.current_page, this.search);
      } else {
        this.fetchAllPort(this.current_page, this.search);
      }
    },
    selectChargerFilter(data) {
      this.current_page = 1;
      if (data == 1) {
        this.fetchData(this.current_page, this.search);
      } else if (data == 2) {
        this.fetchAllChargingSite(this.current_page, this.search);
      } else if (data == 3) {
        this.fetchAllCharger(this.current_page, this.search);
      } else {
        this.fetchAllPort(this.current_page, this.search);
      }
    },
  },
};
</script>
