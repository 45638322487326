<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="Charger Schedule" :path="breadcrumbPath" />
    <!-- tab Change -->
    <div class="row mb-3 px-0 m-0">
      <el-col
        :span="
          $vuetify.display.width >= 1300
            ? 12
            : $vuetify.display.width < 750
            ? 24
            : 16
        "
        class="p-0"
        :class="$vuetify.display.width < 750 ? 'x-center' : ''"
      >
        <el-radio-group
          v-model="uploadFilter"
          text-color="#ffff"
          fill="#234B49"
          :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
        >
          <el-radio-button
            class="start-period all-group-by text-center"
            label="1"
            :class="$vuetify.display.width < 750 ? 'width-50-p' : ''"
          >
            {{
              "Update Chargers" +
              ($vuetify.display.width > 500 ? " (Recommended)" : "")
            }}
          </el-radio-button>
          <el-radio-button
            class="site-group-by text-center end-period"
            label="2"
            :class="$vuetify.display.width < 750 ? 'width-50-p' : ''"
          >
            Simple Upload
          </el-radio-button>
        </el-radio-group>
      </el-col>
    </div>

    <div class="row mb-3 px-0 m-0" v-if="uploadFilter == `1`">
      <!-- alert -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-alert
            v-if="missingColumns.length > 0"
            @close="missingAlertCloseHandle"
            class="ma-2"
            title="file has a wrong structure"
            type="error"
            show-icon
          >
            <p class="mb-0" style="color: #f56c6c">
              {{ "To send a charger, these columns are needed in the file:" }}
            </p>
            <ul>
              <li v-for="(miss, i) in missingColumns" :key="i">
                {{ i + 1 + ". " + miss }}
              </li>
            </ul>
          </el-alert>
        </el-col>
      </el-row>
      <!-- uploader -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-upload
            ref="upload"
            class="upload-demo width-100-p"
            action="#"
            :limit="1"
            :auto-upload="false"
            drag
            :on-change="onChange"
            :on-remove="onRemove"
          >
            <i class="fa fa-cloud-upload f-46"></i>
            <div class="el-upload__text text-white">
              Drop file here or <em>click to upload</em>
            </div>
            <template #tip>
              <div class="el-upload__tip text-white">
                There is a maximum limit of 5MB for the upload process
              </div>
            </template>
          </el-upload>
        </el-col>
      </el-row>
      <!-- steper -->
      <el-row class="m-0 mb-3 px-0">
        <el-col
          :span="24"
          class="p-0"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <el-steps
            :active="activeStep"
            finish-status="success"
            :process-status="successStep"
            simple
            class="step-style width-100-p"
          >
            <el-step title="Load Data" :description="stepLoadDesc" />
            <el-step
              @click="stepperChange(2)"
              style="cursor: pointer"
              title="Check Validate"
              :description="stepValidDesc"
            />
            <el-step
              @click="stepperChange(3)"
              style="cursor: pointer"
              title="Send Data"
              :description="stepSendDesc"
            />
          </el-steps>
        </el-col>
      </el-row>
      <!-- table -->
      <el-row class="m-0 mb-3 px-0" v-if="!hasLargeFile">
        <el-col
          :span="24"
          class="y-center"
          :class="$vuetify.display.width < 750 ? 'x-center' : ''"
        >
          <div class="row m-0 width-100-p">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
              <el-col :span="24" class="mb-2">
                <div class="row m-0">
                  <!-- <el-col
                    class="pl-0"
                    :span="$vuetify.display.width >= 750 ? 14 : 24"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center p-0 order-3 mt-2'
                        : ''
                    "
                  >
                    <el-input
                      size="large"
                      v-model="search"
                      placeholder="Type to search by site name, charger ID, port ..."
                      :prefix-icon="Search"
                      class="table-search"
                    />
                  </el-col> -->
                  <el-col
                    class="p-0"
                    :span="24"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center width-100-p'
                        : 'd-flex justify-content-start'
                    "
                  >
                    <el-button
                      @click="handleExportFile"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <i class="fa fa-file-excel-o"></i>
                    </el-button>
                    <el-button
                      @click="handlePerv"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <i class="fa fa-angle-left"></i>
                      <span class="me-primary pl-3"> Prev </span>
                    </el-button>
                    <el-button
                      @click="handleNext"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <span class="me-primary pr-3"> Next </span>
                      <i class="fa fa-angle-right"></i>
                    </el-button>
                  </el-col>
                  <!-- <el-col
                    class="p-0"
                    :span="$vuetify.display.width >= 750 ? 5 : 12"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center pr-1 order-1 width-100-p'
                        : 'd-flex justify-content-end'
                    "
                  >
                    <el-button
                      color="#0D3937"
                      class="height-40 border-color-light rounded-3 ml-1"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <svg class="stroke-icon width-20 height-20">
                        <use
                          href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                        ></use>
                      </svg>
                      <span class="text-white pl-3"> Filter </span>
                    </el-button>
                  </el-col> -->
                </div>
              </el-col>
              <el-col :span="24" class="p-0">
                <div
                  class="ma-0 width-100-p"
                  v-loading="loading"
                  :element-loading-spinner="svg"
                  element-loading-svg-view-box="-10, -10, 50, 50"
                  element-loading-background="rgba(122, 122, 122, 0.8)"
                >
                  <el-alert
                    v-if="responseAlert.visible"
                    :title="responseAlert.title"
                    :type="responseAlert.type"
                    show-icon
                  >
                    <p
                      class="mb-0"
                      style="color: #67c23a"
                      v-if="response_alert.success_count > 0"
                    >
                      {{ responseAlert.success_desc }}
                    </p>
                    <p
                      class="mb-0"
                      style="color: #f56c6c"
                      v-if="response_alert.error_count > 0"
                    >
                      {{ responseAlert.error_desc }}
                    </p>
                    <p
                      class="mb-0"
                      style="color: #e6a23c"
                      v-if="response_alert.duplicate_count > 0"
                    >
                      {{ responseAlert.duplicate_desc }}
                    </p>
                  </el-alert>
                  <el-table
                    :data="filter_excel_data"
                    :fit="true"
                    height="50vh"
                    class="my-2 data-table"
                    :row-class-name="tableRowClassName"
                    @row-click="handleRowClick"
                    scrollbar-always-on
                  >
                    <el-table-column
                      v-if="$vuetify.display.width >= 500"
                      type="expand"
                      class="coloo"
                    >
                      <template #default="props">
                        <div
                          class="pa-5 bg-me-primary"
                          v-if="
                            props.row.error_message != null &&
                            props.row.error_message.length > 0
                          "
                        >
                          <span class="text-red"> Attention : </span>
                          <p
                            m="t-0 b-2"
                            class="mb-0 bg-me-primary width-700"
                            v-for="(error, i) in props.row.error_message"
                            :key="i"
                          >
                            {{ i + 1 + " : " + error }}
                          </p>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="'Site Name'"
                      :prop="'site_name'"
                      :width="$vuetify.display.width <= 3100 ? 200 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Charger ID'"
                      :prop="'charger_id'"
                      :width="$vuetify.display.width <= 3100 ? 200 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Port ID'"
                      :prop="'port_id'"
                      :width="$vuetify.display.width <= 3100 ? 150 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Install Date'"
                      :prop="'install_date'"
                      :width="$vuetify.display.width <= 3100 ? 150 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Operational Date'"
                      :prop="'operational_date'"
                      :width="$vuetify.display.width <= 3100 ? 180 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Model'"
                      :prop="'model'"
                      :width="$vuetify.display.width <= 3100 ? 160 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Make'"
                      :prop="'make'"
                      :width="$vuetify.display.width <= 3100 ? 200 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Charger Type'"
                      :prop="'charger_type'"
                      :width="$vuetify.display.width <= 3100 ? 150 : ''"
                      sortable
                    >
                      <template #default="scope">
                        <el-tag
                          v-if="
                            scope.row.charger_type != null &&
                            scope.row.charger_type != undefined
                          "
                          :type="
                            scope.row.charger_type.toString().toUpperCase() ===
                            'AC'
                              ? 'success'
                              : 'danger'
                          "
                          disable-transitions
                        >
                          {{ scope.row.charger_type.toString().toUpperCase() }}
                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="'On-Site Renewable'"
                      :prop="'onsite_renewable'"
                      :width="$vuetify.display.width <= 3100 ? 180 : ''"
                      sortable
                    >
                      <template #default="scope">
                        <i
                          v-if="
                            (scope.row.onsite_renewable != null &&
                            scope.row.onsite_renewable != undefined
                              ? scope.row.onsite_renewable
                                  .toString()
                                  .toUpperCase()
                              : '') == 'YES'
                          "
                          class="fa fa-check-square"
                        ></i>
                        <i
                          v-else-if="
                            (scope.row.onsite_renewable != null &&
                            scope.row.onsite_renewable != undefined
                              ? scope.row.onsite_renewable
                                  .toString()
                                  .toUpperCase()
                              : '') == 'NO'
                          "
                          class="fa fa-square-o"
                        ></i>
                        <span v-else> 'NOT PROVIDED' </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="'Battery'"
                      :prop="'onsite_battery'"
                      :width="$vuetify.display.width <= 3100 ? 160 : ''"
                      sortable
                    >
                      <template #default="scope">
                        <i
                          v-if="
                            (scope.row.onsite_battery != null &&
                            scope.row.onsite_battery != undefined
                              ? scope.row.onsite_battery
                                  .toString()
                                  .toUpperCase()
                              : '') == 'YES'
                          "
                          class="fa fa-check-square"
                        ></i>
                        <i
                          v-else-if="
                            (scope.row.onsite_battery != null &&
                            scope.row.onsite_battery != undefined
                              ? scope.row.onsite_battery
                                  .toString()
                                  .toUpperCase()
                              : '') == 'NO'
                          "
                          class="fa fa-square-o"
                        ></i>
                        <span v-else> 'NOT PROVIDED' </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="'Charger Capacity (kW)'"
                      :prop="'charger_capacity_kW'"
                      :width="$vuetify.display.width <= 3100 ? 210 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Port Capacity KW'"
                      :prop="'port_capacity_kw'"
                      :width="$vuetify.display.width <= 3100 ? 210 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Charging Partner'"
                      :prop="'charging_partner'"
                      :width="$vuetify.display.width <= 3100 ? 210 : ''"
                      sortable
                    />
                    <el-table-column
                      :label="'Port Type'"
                      :prop="'port_type'"
                      :width="$vuetify.display.width <= 3100 ? 150 : ''"
                      sortable
                    />
                    <el-table-column
                      v-if="$vuetify.display.width < 500"
                      :label="'Operations'"
                      :prop="'operations'"
                      align="center"
                      width="150"
                    >
                      <template #default="scope">
                        <el-button
                          color="#0d3937"
                          @click.stop="handleShow(scope.$index, scope.row)"
                          size="small"
                          class="me-primary-light"
                        >
                          <i class="fa fa-edit"></i>
                          <span class="pl-1">Update</span>
                        </el-button>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-else
                      fixed="right"
                      :label="'Operations'"
                      :prop="'operations'"
                      align="center"
                      width="150"
                    >
                      <template #default="scope">
                        <el-button
                          color="#0d3937"
                          @click.stop="handleShow(scope.$index, scope.row)"
                          size="small"
                          class="me-primary-light"
                        >
                          <i class="fa fa-edit"></i>
                          <span class="pl-1">Update</span>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="d-flex justify-center">
                    <el-pagination
                      small
                      background
                      layout="prev, pager, next"
                      :total="total_items"
                      v-model:page-size="page_size"
                      v-model:current-page="current_page"
                      class="my-4"
                    />
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row mb-3 px-0 m-0" v-else>
      <SimpleUpload />
    </div>

    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
      @confirm_modal="confirm_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import ShowDetail from "./dialogDetail.vue";
// import Filter from "./filter.vue";
import SimpleUpload from "./simpleUpload.vue";
import { useToast } from "vue-toastification";
import moment from "moment";
import { ElMessageBox } from "element-plus";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "chargers",
  components: {
    Breadcrumbs,
    ShowDetail,
    // Filter,
    SimpleUpload,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "Operation",
          disabled: true,
          href: "",
        },
        {
          title: "Chargers",
          disabled: false,
          href: "/chargers",
          active: true,
        },
      ],
      uploadFilter: "1",
      show: true,
      selectedFile: null,
      excelDataList: [],
      search: "",
      current_page: 1,
      page_size: 100,
      total_items: 100,
      excelData: [],
      beforeUploadValid: true,
      dupRowIndex: [],
      invalidRowIndex: [],
      data: null,
      activeStep: 0,
      successStep: "wait",
      loading: false,
      register_serial_id: "",
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      fromDate: "",
      toDate: "",
      statusRow: 1,
      filterOpen: false,
      filter: {
        fromDate: "",
        toDate: "",
        statusRow: 1,
      },
      hasResponse: true,
      response_alert: {
        all_rows_count: 0,
        success_count: 0,
        duplicate_count: 0,
        error_count: 0,
      },
      correctFile: true,
      alertWrongFile: false,
      responseAlert: {
        visible: false,
        title: "",
        type: "info",
        success_desc: "",
        error_desc: "",
        duplicate_desc: "",
      },
      removeInvalidRow: false,
      missingColumns: [],
    };
  },
  created() {},
  watch: {
    search() {
      this.current_page = 1;
    },
    correctFile(new_val) {
      if (!new_val) {
        this.alertWrongFile = true;
        this.onRemove();
        this.$refs.upload.clearFiles();
      } else {
        this.alertWrongFile = false;
      }
    },
  },
  computed: {
    filter_excel_data() {
      // eslint-disable-next-line no-unused-vars
      let list = this.excelDataList.filter((item, index) => {
        var validOpDate = this.isValidDate(item.operational_date);
        var validInDate = this.isValidDate(item.install_date);
        var validDeDate = this.isValidDate(item.decommissioning_date);
        if (!validOpDate && item.operational_date != null) {
          let operational_milliseconds =
            (item.operational_date - 25569) * 86400 * 1000;
          if (
            operational_milliseconds != null &&
            operational_milliseconds != undefined
          ) {
            item.operational_date = moment
              .utc(operational_milliseconds)
              .format("YYYY/MM/DD");
          }
        }
        if (!validInDate && item.install_date != null) {
          let install_milliseconds = (item.install_date - 25569) * 86400 * 1000;
          if (
            install_milliseconds != null &&
            install_milliseconds != undefined
          ) {
            item.install_date = moment
              .utc(install_milliseconds)
              .format("YYYY/MM/DD");
          }
        }
        if (!validDeDate && item.decommissioning_date != null) {
          let decommissioning_milliseconds =
            (item.decommissioning_date - 25569) * 86400 * 1000;
          if (
            decommissioning_milliseconds != null &&
            decommissioning_milliseconds != undefined
          ) {
            item.decommissioning_date = moment
              .utc(decommissioning_milliseconds)
              .format("YYYY/MM/DD");
          }
        }

        if (
          item.onsite_battery != null &&
          item.onsite_battery != undefined &&
          (item.onsite_battery.toString().toLowerCase() == "yes" ||
            item.onsite_battery.toString().toLowerCase() == "true")
        )
          item.onsite_battery = "YES";
        else if (
          item.onsite_battery != null &&
          item.onsite_battery != undefined &&
          (item.onsite_battery.toString().toLowerCase() == "no" ||
            item.onsite_battery.toString().toLowerCase() == "false")
        )
          item.onsite_battery = "NO";
        else item.onsite_battery = "NOT PROVIDED";
        if (
          item.onsite_renewable != null &&
          item.onsite_renewable != undefined &&
          (item.onsite_renewable.toString().toLowerCase() == "yes" ||
            item.onsite_renewable.toString().toLowerCase() == "true")
        )
          item.onsite_renewable = "YES";
        else if (
          item.onsite_renewable != null &&
          item.onsite_renewable != undefined &&
          (item.onsite_renewable.toString().toLowerCase() == "no" ||
            item.onsite_renewable.toString().toLowerCase() == "false")
        )
          item.onsite_renewable = "NO";
        else item.onsite_renewable = "NOT PROVIDED";

        return (
          ((item.site_name === undefined || item.site_name == null
            ? ""
            : item.site_name
          )
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1 ||
            (item.charger_id === undefined || item.charger_id == null
              ? ""
              : item.charger_id
            )
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1 ||
            (item.certificate_number === undefined ||
            item.certificate_number == null
              ? ""
              : item.certificate_number
            )
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1 ||
            (item.model === undefined || item.model == null ? "" : item.model)
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1 ||
            (item.port_id === undefined || item.port_id == null
              ? ""
              : item.port_id
            )
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1) &&
          (validOpDate &&
          this.isValidDate(this.fromDate) &&
          this.isValidDate(this.toDate)
            ? new Date(item.operational_date) >= new Date(this.fromDate) &&
              new Date(item.operational_date) <= new Date(this.toDate)
            : true) &&
          (this.statusRow == 1 ||
            (this.statusRow == 2 && item.is_duplicate) ||
            (this.statusRow == 3 &&
              Object.keys(item).includes("error_message") &&
              item.error_message.length > 0))
        );
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total_items = list.length;
      let paginated = this.paginate(list, this.page_size, this.current_page);
      return paginated;
    },
  },
  methods: {
    onChange(event, fileList) {
      this.loading = true;
      this.successStep = "process";
      const file = event.raw ? event.raw : null;
      if (
        file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.type !== "application/vnd.ms-excel"
      ) {
        this.loading = false;
        this.successStep = "wait";
        this.$message.error(`select an Excel format!`);
        fileList.pop();
      } else if (file.size / 1024 / 1024 > 5) {
        this.loading = false;
        this.successStep = "wait";
        this.$message.error(`file can not be greater than 5MB!`);
        fileList.pop();
      } else {
        if (file) {
          this.selectedFile = event.raw;
          this.importExcel();
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    onRemove(event, fileList) {
      this.selectedFile = null;
      this.excelDataList = [];
      this.excelData = [];
      this.removeInvalidRow = false;
      this.activeStep = 0;
      this.successStep = "wait";
      this.loading = false;
      this.response_alert = {
        all_rows_count: 0,
        success_count: 0,
        duplicate_count: 0,
        error_count: 0,
      };
      this.correctFile = true;
      this.responseAlert = {
        visible: false,
        title: "",
        type: "info",
        success_desc: "",
        error_desc: "",
        duplicate_desc: "",
      };
    },

    async importExcel() {
      if (this.selectedFile) {
        await this.reader();
      } else {
        this.successStep = "error";
        this.$message.error("Please select an Excel file first.");
        this.loading = false;
      }
      this.response_alert = {
        all_rows_count: 0,
        success_count: 0,
        duplicate_count: 0,
        error_count: 0,
      };
    },

    async reader() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        this.excelData = XLSX.utils.sheet_to_json(worksheet, {
          defval: null,
        });

        const headers = [];
        for (let cell in worksheet) {
          if (
            cell !== "!ref" &&
            ((cell.length == 2 && cell[1] === "1") ||
              (cell.length == 3 && cell[2] === "1"))
          ) {
            headers.push(worksheet[cell].v);
          }
        }
        const requiredColumns = [
          "site_name",
          "year_added",
          "lat",
          "lng",
          "country",
          "state",
          "city",
          "street_name",
          "suburb",
          "postcode",
          "charger_id",
          "install_date",
          "operational_date",
          "model",
          "make",
          "charger_type",
          "onsite_renewable",
          "onsite_battery",
          "port_capacity_kw",
          "charger_capacity_kW",
          "port_id",
          "port_type",
        ];
        this.missingColumns = requiredColumns.filter(
          (column) => !headers.includes(column)
        );
        if (this.missingColumns.length > 0) {
          this.correctFile = false;
        } else {
          this.correctFile = true;
        }
        this.excelDataList = this.excelData;
        this.register_serial_id = btoa(
          (this.$store.state.auth.user != null
            ? this.$store.state.auth.user.id.toString()
            : "0") +
            "-" +
            this.excelDataList.length.toString() +
            "-" +
            Date.now().toString()
        );
        this.loading = false;
      };
      reader.readAsArrayBuffer(this.selectedFile);
      this.activeStep = 1;
    },

    checkDuplicateRow(arrayData) {
      let user = this.$store.state.auth.user;
      this.successStep = "process";
      let uniqueObjects = new Map();
      let ctx = this;
      let validateStatus = true;
      this.dupRowIndex = [];
      this.invalidRowIndex = [];
      arrayData.forEach((obj, i) => {
        var key = `${obj.site_name}-${obj.charger_id}-${obj.port_id}-${obj.operational_date}`;
        obj.error_message = null;
        obj.is_duplicate = false;
        if (uniqueObjects.has(key)) {
          ctx.dupRowIndex.push(i);
          obj.is_duplicate = true;
        } else {
          uniqueObjects.set(key, obj);
        }
        obj.register_id = this.register_serial_id;
        obj.customer_id = user.id;
        obj.error_message = ctx.validateData(obj);
        obj.hasErrorBeforeSend = false;
        obj.hasErrorAfterSend = false;

        if (validateStatus && obj.error_message.length > 0) {
          validateStatus = false;
        }
        if (obj.error_message.length > 0) {
          obj.hasErrorBeforeSend = true;
        }
      });
      if (validateStatus) {
        this.activeStep = 2;
        this.successStep = "process";
      } else {
        this.successStep = "error";
        this.removeInvalidRow = false;
        ElMessageBox.confirm(
          "Do you want to send rows without errors?",
          "confirm",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "Confirm Delete",
          }
        )
          .then(() => {
            this.removeInvalidRow = true;
            this.activeStep = 2;
            this.stepperChange(3);
          })
          .catch(() => {
            this.successStep = "error";
            this.removeInvalidRow = false;
          });
      }
    },

    validateData(item) {
      var error = [];
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      // title
      if (item.is_duplicate == true) {
        error.push(
          "This row is duplicated with the same site name, charger ID, port ID, and operational date"
        );
      }
      // title
      if (item.site_name == null) {
        error.push("Site Name can not be null or empty.");
      }
      // charger_name
      if (item.charger_id == null) {
        error.push("Charger ID can not be null or empty.");
      }
      // year_added
      if (!this.isNumeric(item.year_added)) {
        error.push("Year Added should be of type number.");
      }
      if (item.year_added == null) {
        error.push("Year Added can not be null or empty.");
      }
      if (
        item.year_added != null &&
        item.year_added < 2000 &&
        item.year_added > currentYear
      ) {
        error.push("Year Added must be between 2000 and the current year.");
      }
      // lat
      if (!this.isNumeric(item.lat)) {
        error.push("Lat should be of type number.");
      }
      // lng
      if (!this.isNumeric(item.lng)) {
        error.push("Lng should be of type number.");
      }
      // install_date
      if (
        item.install_date != null &&
        item.install_date != "" &&
        typeof item.install_date.trim() !== "string"
      ) {
        error.push("Install Date must be of type string.");
      }
      if (
        item.install_date != null &&
        item.install_date != "" &&
        !this.isValidDate(item.install_date)
      ) {
        error.push("Install Date is invalid.");
      }
      // operational_date
      if (item.operational_date == null || item.operational_date == "") {
        error.push("Operational Date can not be null or empty.");
      }

      if (
        item.operational_date != null &&
        item.operational_date != "" &&
        typeof item.operational_date.trim() !== "string"
      ) {
        error.push("Operational Date be of type string.");
      }
      if (
        item.operational_date != null &&
        item.operational_date != "" &&
        !this.isValidDate(item.operational_date)
      ) {
        error.push("Operational Date is invalid.");
      }
      // decommissioning_date
      if (
        item.decommissioning_date != null &&
        item.decommissioning_date != "" &&
        typeof item.decommissioning_date !== "string"
      ) {
        error.push("Decommissioning Date be of type string.");
      }
      if (
        item.decommissioning_date != null &&
        item.decommissioning_date != "" &&
        !this.isValidDate(item.decommissioning_date)
      ) {
        error.push("Decommissioning Date is invalid.");
      }
      // charger_capacity_kW
      if (item.charger_capacity_kW == null) {
        error.push("Charger Capacity kW can not be null or empty.");
      }
      if (!this.isNumeric(item.charger_capacity_kW)) {
        error.push("Charger Capacity be of type number.");
      }
      if (
        item.charger_capacity_kW != null &&
        this.isNumeric(item.charger_capacity_kW) &&
        item.charger_capacity_kW < 1 &&
        item.charger_capacity_kW > 1000
      ) {
        error.push("Charger Capacity is invalid value.");
      }
      // model
      if (item.model == null) {
        error.push("Model can not be null or empty.");
      }
      // make
      if (item.make == null) {
        error.push("Make can not be null or empty.");
      }
      // charger_type
      if (item.charger_type == null || item.charger_type == "") {
        error.push("Charger Type can not be null or empty.");
      }
      if (
        item.charger_type != null &&
        item.charger_type != "" &&
        item.charger_type.toString().toLowerCase() != "ac" &&
        item.charger_type.toString().toLowerCase() != "dc"
      ) {
        error.push("The value of charger type can be Ac or DC.");
      }
      // onsite_renewable
      if (
        item.onsite_renewable == null ||
        item.onsite_renewable == undefined ||
        String(item.onsite_renewable) == ""
      ) {
        error.push("Onsite Renewable can not be null or empty.");
      }
      if (
        item.onsite_renewable != null &&
        item.onsite_renewable != undefined &&
        item.onsite_renewable != "" &&
        String(item.onsite_renewable).toLowerCase() != "yes" &&
        String(item.onsite_renewable).toLowerCase() != "true" &&
        String(item.onsite_renewable).toLowerCase() != "no" &&
        String(item.onsite_renewable).toLowerCase() != "false"
      ) {
        error.push("The value of Onsite Renewable can be Yes or No.");
      }
      // onsite_battery
      if (
        item.onsite_battery == null ||
        item.onsite_battery == undefined ||
        String(item.onsite_battery) == ""
      ) {
        error.push("Onsite Battery can not be null or empty.");
      }
      if (
        item.onsite_battery != null &&
        item.onsite_battery != undefined &&
        item.onsite_battery != "" &&
        String(item.onsite_battery).toLowerCase() != "yes" &&
        String(item.onsite_battery).toLowerCase() != "true" &&
        String(item.onsite_battery).toLowerCase() != "no" &&
        String(item.onsite_battery).toLowerCase() != "false"
      ) {
        error.push("The value of Onsite Battery can be Yes or No.");
      }
      // port_id
      if (item.port_id == null || item.port_id == "") {
        error.push("Port ID can not be null or empty.");
      }
      //port_type
      if (item.port_type == null || item.port_type == "") {
        error.push("Port Type can not be null or empty.");
      }
      //state
      if (item.state == null || item.state == "") {
        error.push("State can not be null or empty.");
      }
      //street_name
      if (item.street_name == null || item.street_name == "") {
        error.push("Street Name can not be null or empty.");
      }
      //suburb
      if (item.suburb == null || item.suburb == "") {
        error.push("Suburb can not be null or empty.");
      }
      //postcode
      if (item.postcode == null || item.postcode == "") {
        error.push("Postcode can not be null or empty.");
      }
      //state
      if (item.state == null || item.state == "") {
        error.push("State can not be null or empty.");
      }
      //port_capacity_kw
      if (item.port_capacity_kw == null || item.port_capacity_kw == "") {
        error.push("Port Capacity kw can not be null or empty.");
      }
      if (!this.isNumeric(item.port_capacity_kw)) {
        error.push("Port Capacity be of type number.");
      }
      if (
        item.port_capacity_kw != null &&
        item.port_capacity_kw < 1 &&
        item.port_capacity_kw > 1000
      ) {
        error.push("Port Capacity is invalid value.");
      }
      return error;
    },

    validateAfterSend(
      keyDup,
      keyInvalidSite,
      keyInvalidCharger,
      keyInvalidPort
    ) {
      let validateStatus = true;
      this.dupRowIndex = [];
      this.response_alert.all_rows_count = this.excelDataList.length;
      this.response_alert.success_count = 0;
      this.response_alert.duplicate_count = 0;
      this.response_alert.error_count = 0;

      this.excelDataList.forEach((obj) => {
        let hasError = false;
        let hasDuplicate = false;
        let obj_site_name =
          obj.site_name == null || obj.site_name == undefined
            ? ""
            : obj.site_name.toString().toUpperCase();
        let obj_charger_id =
          obj.charger_id == null || obj.charger_id == undefined
            ? ""
            : obj.charger_id.toString().toUpperCase();
        let obj_port_id =
          obj.port_id == null || obj.port_id == undefined
            ? ""
            : obj.port_id.toString().toUpperCase();
        let obj_operational_date =
          obj.operational_date == null || obj.operational_date == undefined
            ? ""
            : obj.operational_date.toString();
        if (
          keyDup != "" &&
          keyDup
            .split(",")
            .includes(
              `${obj_site_name};${obj_charger_id};${obj_port_id};${obj_operational_date}`
            )
        ) {
          obj.error_message.push(
            "The charger with this information already exist in the database."
          );
          hasDuplicate = true;
          obj.is_duplicate = true;
          // obj.hasErrorAfterSend = true;
        }
        if (
          keyInvalidSite != "" &&
          keyInvalidSite.split(",").includes(`${obj_site_name}`)
        ) {
          obj.error_message
            .push(`Data inconsistency detected: This charging site has discrepancies in key fields compared to other charging sites. 
							Fields such as latitude, longitude, country, state, city, suburb, street name, street number, postcode, or address comment 
							contain different values`);
          hasError = true;
          obj.hasErrorAfterSend = true;
        }
        if (
          keyInvalidCharger != "" &&
          keyInvalidCharger
            .split(",")
            .includes(
              `${obj_site_name};${obj_charger_id};${obj_operational_date}`
            )
        ) {
          // obj.error_message.push("invalid charger");
          obj.error_message
            .push(`Data inconsistency detected: this charger has discrepancies in key fields compared to other chargers. 
							Fields such as latitude, longitude, country, state, city, suburb, street name, street number, postcode, or address comment 
							contain different values`);
          hasError = true;
          obj.hasErrorAfterSend = true;
        }
        if (
          keyInvalidPort != "" &&
          keyInvalidPort
            .split(",")
            .includes(
              `${obj_site_name};${obj_charger_id};${obj_port_id};${obj_operational_date}`
            )
        ) {
          obj.error_message
            .push(`Data inconsistency detected: This charger port has discrepancies in key fields compared to one or more other charger ports. 
            Fields such as charger id, port ID, operational date, make, model, port capacity (kW), port type, or description 
            contain different values`);
          // eslint-disable-next-line no-unused-vars
          hasError = true;
          obj.hasErrorAfterSend = true;
        }
        if (validateStatus && obj.error_message.length > 0) {
          validateStatus = false;
        }
        if (hasDuplicate || obj.is_duplicate)
          this.response_alert.duplicate_count += 1;
        // if (hasError && !hasDuplicate) this.response_alert.error_count += 1;
        if (obj.hasErrorAfterSend || obj.hasErrorBeforeSend)
          this.response_alert.error_count += 1;
      });

      let successCount = this.excelDataList.filter(
        (item) => item.error_message.length == 0
      );
      this.response_alert.success_count = successCount.length;

      // this.excelDataList = this.excelDataList.filter(
      //   (item) => !item.is_duplicate
      // );
      this.excelDataList = this.excelDataList.filter(
        (item) => item.error_message.length != 0
      );
      return validateStatus;
    },

    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      if (row.is_duplicate) {
        return "warning-row";
      } else if (
        Object.keys(row).includes("error_message") &&
        row.error_message.length > 0
      ) {
        return "error-row";
      }
      return "";
    },

    isValidDate(dateString) {
      const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },

    isNumeric: function (n) {
      if (n != null) return !isNaN(parseFloat(n)) && isFinite(n);
      else return true;
    },

    stepperChange(num) {
      if (num == 2 && this.excelDataList.length > 0 && this.activeStep == 1) {
        this.checkDuplicateRow(this.excelDataList);
      } else if (
        num == 3 &&
        this.excelDataList.length > 0 &&
        this.activeStep == 2
      ) {
        this.sendDate(this.excelDataList);
      }
    },

    sendDate(jsonData) {
      this.loading = true;
      let duplicate = "";
      let invalid_charger_data = "";
      let invalid_port_data = "";
      let invalid_site_data = "";
      jsonData = jsonData.filter((item) => {
        return (
          !item.is_duplicate &&
          (item.error_message == null || item.error_message.length == 0)
        );
      });
      if (jsonData.length == 0) {
        this.loading = false;
        useToast().error(" All rows have errors. There is no row to send.", {
          icon: "fa fa-times",
          position: "bottom-right",
          timeout: 2000,
        });
      } else {
        UserService.setChargers(jsonData)
          .then(
            (response) => {
              if (response.data.data.duplicate != null) {
                duplicate = response.data.data.duplicate;
              }
              if (response.data.data.invalid_charger_data != null) {
                invalid_charger_data = response.data.data.invalid_charger_data;
              }
              if (response.data.data.invalid_port_data != null) {
                invalid_port_data = response.data.data.invalid_port_data;
              }
              if (response.data.data.invalid_site_data != null) {
                invalid_site_data = response.data.data.invalid_site_data;
              }
              let isValid = this.validateAfterSend(
                duplicate,
                invalid_site_data,
                invalid_charger_data,
                invalid_port_data
              );
              if (isValid) {
                this.activeStep = 3;
                useToast().success(" Send charger file successfully ", {
                  icon: "fa fa-check",
                  position: "bottom-right",
                  timeout: 2000,
                });
              } else {
                this.activeStep = 1;
                this.successStep = "error";
                useToast().error(
                  " Please check the details for each item sent.",
                  {
                    icon: "fa fa-times",
                    position: "bottom-right",
                    timeout: 2000,
                  }
                );
              }
              this.responseAlert.visible = true;
              this.responseAlert.title = `From the ${this.response_alert.all_rows_count} rows sent in the Excel file`;
              this.responseAlert.success_desc =
                `Success : ` +
                `${this.response_alert.success_count} records were registered successfully.`;
              this.responseAlert.duplicate_desc =
                `Duplicate : ` +
                `${this.response_alert.duplicate_count} records were were detected as duplicates.`;
              this.responseAlert.error_desc =
                `Oops : ` +
                `it seems that ${this.response_alert.error_count} records are not registered. Please check the items listed below for more details.`;
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
              useToast().error(
                " Please check the details for each item sent.",
                {
                  icon: "fa fa-times",
                  position: "bottom-right",
                  timeout: 2000,
                }
              );
            }
          )
          .catch((error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
            useToast().error(" Please check the details for each item sent.", {
              icon: "fa fa-times",
              position: "bottom-right",
              timeout: 2000,
            });
          })
          .finally(() => (this.loading = false));
      }
    },

    // eslint-disable-next-line no-unused-vars
    handleRowClick(row, column, event) {
      // console.log(row, column, event);
      console.log(row.hasErrorBeforeSend, row.hasErrorAfterSend);
      console.log(row.is_duplicate);
    },
    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },

    confirm_modal(event) {
      this.excelDataList[this.current_row_index] = event;
      this.dupRowIndex.splice(this.current_row_index, 1);
      event.is_duplicate = false;
      this.show_modal = false;
      this.activeStep = 1;
      this.successStep = "process";
    },

    handleShow(index, data) {
      this.current_row_index = index;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },

    handlePerv() {
      if (this.activeStep == 1 && this.successStep == "error") {
        this.activeStep = 1;
        this.stepperChange(2);
      } else if (this.activeStep == 2) {
        this.activeStep = 1;
        this.successStep = "process";
      } else if (this.activeStep == 3) {
        this.stepperChange(2);
      }
    },

    handleNext() {
      if (this.activeStep == 1) {
        this.stepperChange(2);
      } else if (this.activeStep == 2 && this.successStep == "process") {
        this.stepperChange(3);
      }
    },

    current_change_click() {},

    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },

    handleExportFile() {
      if (this.excelDataList.length > 0) {
        let modifiedArr = this.excelDataList.map((item) => {
          let {
            // eslint-disable-next-line no-unused-vars
            register_id,
            // eslint-disable-next-line no-unused-vars
            customer_id,
            // eslint-disable-next-line no-unused-vars
            is_duplicate,
            ...rest
          } = item;
          return rest;
        });
        const ws = XLSX.utils.json_to_sheet(modifiedArr, {
          header: Object.keys(modifiedArr[0]),
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "ChargerList.xlsx");
      }
    },

    missingAlertCloseHandle() {
      this.alertWrongFile = false;
      this.missingColumns = [];
    },

    refresh_filter(event) {
      this.filter = event.filter;
      this.current_page = 1;
      this.fromDate = this.filter.fromDate;
      this.toDate = this.filter.toDate;
      this.statusRow = this.filter.statusRow;
    },

    reset_filter(event) {
      this.current_page = 1;
      this.fromDate = "";
      this.toDate = "";
      this.search = "";
      this.statusRow = 1;
      this.filter = event.filter;
    },

    filterAction(event) {
      if (event.kind == "refresh") {
        this.refresh_filter(event);
      } else if (event.kind == "reset") {
        this.reset_filter(event);
      } else if (event.kind == "excel") {
        this.handleExportFile();
      } else if (event.kind == "prev") {
        this.handlePerv();
      } else if (event.kind == "next") {
        this.handleNext();
      }
    },

    filterSearch(event) {
      this.search = event.search;
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cell {
  text-align: center !important;
}

.starter-main .card-body ul {
  list-style-type: none !important;
}

.el-pager {
  -webkit-user-select: none !important;
  user-select: none !important;
  list-style: none !important;
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.el-upload-list {
  margin: 10px 0 0 !important;
  padding: 0 !important;
  list-style: none !important;
  position: relative !important;
}

.upload-demo > ul {
  top: 0 !important;
  left: 0 !important;
}

.charger-tab > ul {
  top: 18px !important;
}

.upload-demo > .el-upload > .el-upload-dragger {
  background-color: #0d3937 !important;
  color: white !important;
  border-radius: 9px !important;
}

.step-style {
  background-color: #0d3937 !important;
  color: white !important;
  border-radius: 9px !important;
}

.upload-demo > .el-upload-list > .el-upload-list__item {
  background-color: #0d3937 !important;
  color: white !important;
  padding: 5px 10px !important;
}

.is-process {
  color: white !important;
  border-color: white !important;
}

.width-700 {
  width: 700px;
}
</style>
