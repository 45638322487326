<!-- eslint-disable vue/multi-word-component-names -->
<template>

  <el-row :span="24">
    <el-col :span="24">
      <div class="row m-0">

        <el-row :span="24" class="m-0">
        <el-col
          :span="
            hasGroupBy &&
            timeOfDayGroupByListProp != null &&
            timeOfDayGroupByListProp.length > 0
              ? $vuetify.display.width >= 1200
                ? 8
                : 24
              : $vuetify.display.width >= 500
              ? 24
              : 24
          "
          class="d-flex align-item-center"
          :class="
            $vuetify.display.width < 500
              ? 'x-center mt-2'
              : hasPeriod == true
              ? 'justify-end'
              : ''
          "
        >
          <div class="m-0" >
            <!-- Delete Sessions Filter button -->
            <el-button
              v-if="hasCustomDate"
              color="#0D3937"
              class="height-40 border-color-light rounded-3"
              @click="customDate = !customDate"
            >
              <svg class="stroke-icon width-20 height-20">
                <use href="@/assets/svg/icon-sprite.svg#stroke-date"></use>
              </svg>
              <span class="text-white pl-3"> Delete Sessions </span>
            </el-button>
          </div>
        </el-col>
        </el-row>
      </div>
    </el-col>
  
    <el-col :span="24">

    <!-- delete session filter -->
    <el-row v-if="customDate" class="y-center m-0 p-2">
      <el-col :span="24">
      <div
        class="row y-center m-0 mt-2 mx-4 p-2 bg-me-primary border-color-light rounded-4 width-100-p"
        :class="$vuetify.display.width >= 700 ? 'height1-100' : ''"
      >
        
        <el-form :label-position="`top`" class="text-center">
          <el-space
            class="mt-2"
            fill
            style="width: 100%"
            v-if="
              hasCharger ||
              ($store.state.auth.user != null && $store.state.auth.user.is_admin)
            "
          >
            <!-- delete session checkbox -->
            <el-row gutter=20>
              <el-col :span="$vuetify.display.width >= 1200 ? 6 : $vuetify.display.width >= 500 ? 24 : 24">
                <label class="radio-label" :style="{'width': $vuetify.display.width <= 1485 ? 'auto' : $vuetify.display.width <= 1578 ? '400px' : $vuetify.display.width <= 1651 ? '450px' : '500px'}">
                  Please select the method and time period for removing sessions.
                </label>
              </el-col>
              <el-col :span="$vuetify.display.width >= 1200 ? 14 : $vuetify.display.width >= 500 ? 24 : 24">
                <el-radio-group 
                  v-model="selectedDeleteBy"
                  label= "Select delete By" 
                  >
                  <el-radio label="charger" >Charger</el-radio>
                  <el-radio label="site" >Charger Site</el-radio>
                  <el-radio label="port" >Charger Port</el-radio>
                </el-radio-group>
              </el-col>


              <!-- select customer dropdown -->
              <el-col
                class="pl-0"
                :span="
                  $vuetify.display.width >= 1400
                    ? 8
                    : $vuetify.display.width <= 500
                    ? 24
                    : 12
                "
                :offset="$vuetify.display.width >= 1400 ? 4 : 0"
                v-if="
                  hasCustomer &&
                  $store.state.auth.user != null &&
                  $store.state.auth.user.is_admin
                "
              >
                <el-form-item
                  class="label-style"
                  prop="customer"
                  label="Customer : "
                >
                  <el-select
                    clearable
                    v-model="filter.customer_id"
                    placeholder="Select Customer"
                    class="w-100"
                  >
                    <!-- Render the header row once -->
                  <template v-if="customers.length > 0">
                    <div class="option-content option-header">
                      <span class="option-item">Name</span>
                      <span class="option-item">Phone</span>
                    </div>
                  </template>
                    <el-option
                      v-for="(item, index) in customers"
                      :key="item.id || index"
                      :label="item.full_name || 'undifined'"
                      :value="item.id || 'undifined'"
                      @click="handleSelectCustomer(item.id, item.full_name)"
                    >
                      <div class="option-content option-row">
                        <span class="option-item">{{ item.full_name }}</span>
                        <span class="option-item">{{ item.phone }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- select customer dropdown end -->
              </el-col>

              <!-- select chargers dropdown -->
              <el-col
                v-if="hasCharger"
                class="pr-0"
                :class="
                  $store.state.auth.user == null ||
                  !$store.state.auth.user.is_admin
                    ? 'pl-0'
                    : ''
                "
                :span="
                  $vuetify.display.width >= 1400
                    ? 8
                    : $vuetify.display.width <= 500
                    ? 24
                    : 12
                "
              >
                <el-form-item
                  class="label-style"
                  prop="charger"
                  :label="selectedDeleteBy === 'port' ? 'Charger Ports: ' : selectedDeleteBy === 'site' ? 'Charger Sites: ' : selectedDeleteBy === 'charger' ? 'Chargers: ' : 'Select Delete By'"
                >
                  <el-select
                    v-if="selectedDeleteBy == `charger`"
                    v-model="filter.charger_name"
                    class="w-100"
                    filterable
                    clearable
                    :loading="chargerLoading"
                    placeholder="Select Charger (search by charger name)"
                  >
                  <template v-if="chargers.length > 0">
                    <div class="option-content option-header">
                      <span class="option-item">Charger Name</span>
                      <span class="option-item">Site Name</span>
                      <span class="option-item">Operational Date</span>
                    </div>
                  </template>
                    <el-option
                      v-for="(item, index) in chargers_dropdown"
                      :key="item.charger_id || index"
                      :label="item.charger_name || 'undifined'"
                      :value="item.charger_id || 'undifined'"
                      @click="handleSelectCharger(item.charger_site_id, item.site_name, item.charger_name, item.charger_id)"
                    >
                      <div class="option-content option-row">
                        <span class="option-item">{{ item.charger_name }}</span>
                        <span class="option-item">{{ item.site_name.length > 15 ? item.site_name.substring(0, 15) : item.site_name }}</span>
                        <span class="option-item">{{ item.operational_date }}</span>
                      </div>
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="this.selectedDeleteBy == 'port'"
                    v-model="filter.charger_name"
                    class="w-100"
                    filterable
                    clearable
                    :loading="portLoading"
                    placeholder="Select Charger Port (search by port name)"
                  >
                  <template v-if="chargers.length > 0">
                    <div class="option-content option-header">
                      <span class="option-item">Port Name</span>
                      <span class="option-item">Site Name</span>
                      <span class="option-item">Charger Name</span>
                      <span class="option-item">Operational Date</span>
                    </div>
                  </template>
                    <el-option
                      v-for="(item, index) in charger_ports_dropdown"
                      :key="item.unique_port_id || index"
                      :label="item.port_id || 'undifined'"
                      :value="item.unique_port_id || 'undifined'"
                      @click="handleSelectCharger(item.charger_site_id, item.site_name, item.charger_name, item.charger_id, item.unique_port_id)"
                    >
                      <div class="option-content option-row">
                        <span class="option-item">{{ item.port_id }}</span>
                        <span class="option-item">{{ item.site_name.length > 15 ? item.site_name.substring(0, 15) : item.site_name }}</span>
                        <span class="option-item">{{ item.charger_name }}</span>
                        <span class="option-item">{{ item.operational_date }}</span>
                      </div>    
                    </el-option>
                  </el-select>
                  <el-select
                    v-if="this.selectedDeleteBy == 'site'"
                    v-model="filter.charger_name"
                    class="w-100"
                    filterable
                    clearable
                    :loading="siteLoading"
                    placeholder="Select Charger Site (search by site name)"
                  >
                  <template v-if="chargers.length > 0">
                    <div class="option-content option-header">
                      <span class="option-item">Site Name</span>
                          <span class="option-item">State</span>
                          <span class="option-item">Suburb</span>
                    </div>
                  </template>
                    <el-option
                      v-for="(item, index) in charger_sites_dropdown"
                      :key="item.unique_charger_id || index"
                      :label="item.site_name || 'undifined'"
                      :value="item.unique_charger_id || 'undifined'"
                      @click="handleSelectCharger(item.charger_site_id, item.site_name, item.charger_name, item.charger_id, item.unique_port_id, item.unique_charger_id)"
                    >
                      <div class="option-content option-row">
                        <span class="option-item">{{ item.site_name.length > 15 ? item.site_name.substring(0, 15) : item.site_name }}</span>
                          <span class="option-item">{{ item.state }}</span>
                          <span class="option-item">{{ item.suburb.length > 15 ? item.suburb.substring(0, 15) : item.suburb }}</span>
                      </div>  
                                    
                    </el-option>
                  </el-select>


                </el-form-item>
              </el-col>

              <el-col v-if="showMP" :span="24">
                <el-radio-group 
                  v-model="selectTimePeriod"
                  label= "Select Time Period" 
                  >
                  <!-- <el-radio :disabled="!showCMP" label="current_mp" @click="switchTimePeriod('current_mp')">Current MP</el-radio>
                  <el-radio :disabled="!showRMP" label="revision_mp" @click="switchTimePeriod('revision_mp')">Revision MP</el-radio> -->
                  <el-radio  label="current_mp" @click="switchTimePeriod('current_mp')">Current MP</el-radio>
                  <el-radio label="revision_mp" @click="switchTimePeriod('revision_mp')">Revision MP</el-radio>
                </el-radio-group>
              </el-col>

              <!-- select charger dropdown end -->
            </el-row>
          </el-space>

          <!-- delete session datetime picker -->
          <el-space v-if="!showMP" fill style="width: 100%">
            <div>
              <span v-if="!showCMP" class="warning-message">Warning: There is not any current monitoring period in the database.</span><br/>
              <span v-if="!showRMP" class="warning-message">Warning: There is not any revision monitoring period in the database.</span>
            </div>
          </el-space>
          <el-space v-if="showMP" fill style="width: 100%">
            <div>
              <span v-if="!showCMP && showCMPwarning" class="warning-message">Warning: There is not any current monitoring period in the database.</span><br/>
              <span v-if="!showRMP && showRMPwarning" class="warning-message">Warning: There is not any revision monitoring period in the database.</span>
            </div>
            <el-row v-if="showTimePicker" gutter=20>
              <el-col
                class="pl-0"
                :span="
                  $vuetify.display.width >= 1400
                    ? 8
                    : $vuetify.display.width <= 500
                    ? 24
                    : 12
                "
                :offset="$vuetify.display.width >= 1400 ? 4 : 0"
              >
                <el-form-item class="label-style mb-1" label="Start Date : ">
                  <el-date-picker
                    v-model="deleteByDatetime.startDate"
                    type="datetime"
                    placeholder="Pick a start date and time"
                    size="default"
                    class="w-100"
                    format="DD/MM/YYYY HH:mm:ss"
                    date-format="DD/MM/YYYY"
                    time-format="HH:mm:ss"
                    value-format="YYYY-MM-DD HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
              <el-col
                class="pr-0"
                :span="
                  $vuetify.display.width >= 1400
                    ? 8
                    : $vuetify.display.width <= 500
                    ? 24
                    : 12
                "
              >
                <el-form-item class="label-style mb-1" label="End Date : ">
                  <el-date-picker
                    v-model="deleteByDatetime.endDate"
                    type="datetime"
                    placeholder="Pick a end date and time"
                    size="default"
                    class="w-100"
                    format="DD/MM/YYYY HH:mm:ss"
                    date-format="DD/MM/YYYY"
                    time-format="HH:mm:ss"
                    value-format="YYYY-MM-DD HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-space>

        </el-form>

        <!-- delete submite button -->
        <el-row v-if="showMP && showTimePicker" gutter=20 class="float-right mt-5 width-100-p">
          <el-col
            :span="$vuetify.display.width >= 700 ? 12 : 24"
            :offset="$vuetify.display.width >= 1400 ? 4 : 0"
            class="px-1 y-center height-50"
            :class="$vuetify.display.width < 700 ? 'py-2 x-end' : 'height-100-p'"
          >
            <el-button
              color="#ddf0ac"
              @click="handleDeleteSession"
              class="height-40 rounded-3"
              :class="$vuetify.display.width < 700 ? 'x-end' : 'x-y-center'"
            >
              <svg class="stroke-icon width-20 height-20">
                <use href="@/assets/svg/icon-sprite.svg#stroke-refresh"></use>
              </svg>
              <span class="me-primary pl-3"> Delete </span>
            </el-button>
          </el-col>
        </el-row>
      </div>
      </el-col>

    </el-row>
    </el-col>

  </el-row>


    
 

</template>

<script>
  import UserService from "@/services/user.service";
  import { ElMessageBox } from "element-plus";
  import userService from "@/services/user.service";


  export default {

    components: {
 
    },


    props: {
      propCardData: {
        type: Object,
        default: null,
      },
      stateProp: {
        type: Array,
        default: null,
      },
      timeOfDayGroupByListProp: {
        type: Array,
        default: null,
      },
      hasGroupBy: {
        type: Boolean,
        default: false,
      },
      hasPeriod: {
        type: Boolean,
        default: false,
      },
      hasCustomDate: {
        type: Boolean,
        default: false,
      },
      hasLocation: {
        type: Boolean,
        default: false,
      },
      hasCharger: {
        type: Boolean,
        default: false,
      },
      hasCustomer: {
        type: Boolean,
        default: true,
      },
      periodNameProp: {
        type: String,
        default: "All",
      },
    },
  
    watch: {
      // reset datetime range when above limite period
      'deleteByDatetime.startDate'(newVal) {
        if(newVal < this.filter.startDate) {
          this.deleteByDatetime.startDate = this.filter.startDate;
          return ElMessageBox.alert(
            `Start date cannot be before start of ${this.selectTimePeriod.replace("current_mp", "Current MP").replace("revision_mp", "Revision MP")}: ${this.filter.startDate}`,
            {
              confirmButtonText: "OK",
              type: "error",
            }
          );
        }
        if(newVal > this.filter.endDate) {
          this.deleteByDatetime.startDate = this.filter.startDate;
          return ElMessageBox.alert(
            `Start date cannot be after end of ${this.selectTimePeriod.replace("current_mp", "Current MP").replace("revision_mp", "Revision MP")}: ${this.filter.endDate}`,
            {
              confirmButtonText: "OK",
              type: "error",
            }
          );
        }
      },
      'deleteByDatetime.endDate'(newVal){
        if(newVal > this.filter.endDate) {
          this.deleteByDatetime.endDate = this.filter.endDate;
          return ElMessageBox.alert(
            `End date cannot be after the end of ${this.selectTimePeriod.replace("current_mp", "Current MP").replace("revision_mp", "Revision MP")}: ${this.filter.endDate}`,
            {
              confirmButtonText: "OK",
              type: "error",
            }
          );
        }
        if(newVal < this.filter.startDate) {
          this.deleteByDatetime.endDate = this.filter.endDate;
          return ElMessageBox.alert(
            `End date cannot be before the start of ${this.selectTimePeriod.replace("current_mp", "Current MP").replace("revision_mp", "Revision MP")}: ${this.filter.startDate}`,
            {
              confirmButtonText: "OK",
              type: "error",
            }
          );
        }
      },
      // reset datetime range when above limite period

      'selectedCustomer.customer_id': {
            handler: "updateChargerList",
            deep: true,
          },

      'customDate'(newVal) {
        if(!newVal) {
          this.resetAll();
        }
        if(newVal) {
          this.fetchMonitoringPeriod();
        }
      },

      'selectedDeleteBy'(newVal) {
        // console.log("newVal select delete by", newVal);

        this.$emit("showchildDropdownTable", false);
        
        if(newVal == "charger") {
          this.switchChargerDropdownData('charger');
        }
        if(newVal == "site") {
          this.switchChargerDropdownData('site');
        }
        if(newVal == "port") {
          this.switchChargerDropdownData('port');
        }
      }
    },

    computed: {
      selectedChargerInfo() {
        // console.log("this.chargers", this.chargers);
        // console.log("this.selecteddeleteBy", this.selectedDeleteBy);
        let type = this.selectedDeleteBy;
        let data = [];
        if(type == "charger") {
          data = this.chargers.filter(
            (item) => item.charger_id == this.selectedChargerName.charger_id
          );
        }
        if(type == "port") {
          data = this.chargers.filter(
            (item) => item.unique_port_id == this.selectedChargerName.unique_port_id
          );
        }
        if(type == "site") {
          data = this.chargers.filter(
            (item) => item.charger_site_id == this.selectedChargerName.charger_site_id
          );
        }
        return data;
        
        // return this.chargers.filter(
        //   (item) => item.charger_id == this.selectedChargerName.charger_id
        // );
      },
      tableColumns() {
        if(this.selectedChargerInfo.length > 0) {
          const columns = Object.keys(this.selectedChargerInfo[0]).map(item => {
            return {
              prop: item,
              label: this.formatTableLabel(item),
            };
          });
          return columns;
        } else {
          return [];
        }
      },

      showTimePicker(){
        if(this.selectTimePeriod === ''){
          return false
        } else if(this.showCMP && !this.showRMPwarning) {
          return true
        } else if(this.showRMP && !this.showCMPwarning) {
          return true
        } else {
          return false
        }
      }

    },
  
    data() {
      return {

        showMP: false,
        showCMP: false,
        showRMP: false,

        showRMPwarning: false,
        showCMPwarning: false,


        allInOneChargerData: null,

        fetchedTimePeriod: [],

        checkDelete: false,

        // radio select delete by charger, site, port
        selectedDeleteBy: 'charger', // default selected delete by charger

        // radio select time period
        selectTimePeriod: '', // default selected time period

        customDate: false,
        states: ["All"],

        customers: [], //customer dropdown list

        chargers: [], // charger dropdown list

        customer_chargers: [],
        customer_charger_ports: [],
        customer_charger_sites: [],

        chargers_dropdown: [],
        charger_ports_dropdown: [],
        charger_sites_dropdown: [],
        

        defaultTimeStart: null,
        defaultTimeEnd: null,

        // selected customer from first dropdown
        selectedCustomer: {
            customer_id: null,
            full_name: null,
        },
        // selected charger from second dropdown
        selectedChargerName: {
            charger_id: null, // unique charger id for delete

            charger_site_id: null,
            charger_site_name: null,
            charger_name: null,

            unique_port_id: null, // unique port id for delete

            unique_site_id: null, // unique site id for delete
        },

        deleteAll: {
          uniq_charger_id: null,
          startDate: null,
          endDate: null,
        },

        deleteByDatetime: {
          startDate: null,
          endDate: null,
          uniq_charger_id: null,
        },

        deleteRequest: null,

        filter: {
          customer_id: null,
          state: "All",
          startDate: null,
          endDate: null,
          groupBy: null,
          groupByItem: null,
          charger_name: null,
        },
        // groupByItems: [
        //   { key: "all", title: "All" },
        //   { key: "suburb", title: "By Suburb" },
        //   { key: "elec_region", title: "By Electricity Region" },
        //   { key: "charging_site", title: "By Charging Site" },
        //   { key: "post_code", title: "By Postcode" },
        // ],
        // byList: [],
        chargerLoading: false,
        portLoading: false,
        siteLoading: false,
      };
    },
    mounted() {
        this.customer_chargers = [];
        this.resetAll();

      // this.selectedPeriod =
      if (
        this.$store.state.auth.user != null &&
        this.$store.state.auth.user.is_admin &&
        this.hasCustomDate
      )
      

        this.fetchMonitoringPeriod();

        this.fetchCustomerData();
  
        // this.fetchChargerData();
      // if (this.periodNameProp == "2023 MP") {
      //   let date = this.dateCalc(this.periodNameProp);
      //   this.filter.startDate = date.start_date;
      //   this.filter.endDate = date.end_date;
      // }
      // console.log(this.periodNameProp);
      // console.log(this.periodNameProp == "2023 MP");
    },
    methods: {

      formatTableLabel(label) {

        let splitLabel = label.toLowerCase().split("_");
        for (let i = 0; i < splitLabel.length; i++) {
          splitLabel[i] = splitLabel[i].replace(/\b\w/g, char => char.toUpperCase());
        }
        label = splitLabel.join(" ");
        return label;
        },

      
      /**
       * Switches the time period based on the selected event and updates the filter and deleteByDatetime dates.
       *
       * Description:
       * - Logs the event to the console for debugging purposes.
       * - Switches the time period based on the event type ("current_mp" or "revision_mp").
       * - Updates the `filter` and `deleteByDatetime` objects with the corresponding start and end dates from the `fetchedTimePeriod` array.
       *
       * @param {string} event - The event type indicating the time period to switch to (e.g., "current_mp", "revision_mp").
       */
      switchTimePeriod(event) {
        this.showCMPwarning = false;
        this.showRMPwarning = false;
        // console.log(event);
        switch (event) {
          case "current_mp":
            this.showCMPwarning = true;
            this.filter.startDate = this.fetchedTimePeriod[0].start_date;
            this.filter.endDate = this.fetchedTimePeriod[0].end_date;
            this.deleteByDatetime.startDate = this.fetchedTimePeriod[0].start_date;
            this.deleteByDatetime.endDate = this.fetchedTimePeriod[0].end_date;
            break;
          case "revision_mp":
            this.showRMPwarning = true;
            this.filter.startDate = this.fetchedTimePeriod[1].start_date;
            this.filter.endDate = this.fetchedTimePeriod[1].end_date;
            this.deleteByDatetime.startDate = this.fetchedTimePeriod[1].start_date;
            this.deleteByDatetime.endDate = this.fetchedTimePeriod[1].end_date;
            break;
          default:
            break;
        }
      },

        // handleDeleteSession
        /**
         * Handles the deletion of sessions based on the selected charger type and date range.
         *
         * Description:
         * - Determines the type of deletion (charger, site, or port) and constructs the `deleteRequest` object accordingly.
         * - Displays a confirmation dialog to the user, asking if they want to proceed with the deletion.
         * - If the user confirms, calls the `submitDelete` method to perform the deletion.
         * - If the user cancels, logs a message indicating that the deletion was cancelled.
         *
         * @returns {Promise<void>} - A promise that resolves when the deletion process is completed.
         */
        async handleDeleteSession() {

            let type = this.selectedDeleteBy;
            switch (type) {
              case "charger":
                this.deleteRequest={
                  type: "charger",
                  uniq_charger_id: this.selectedChargerName.charger_id,
                  startDate: this.deleteByDatetime.startDate,
                  endDate: this.deleteByDatetime.endDate,
                }
                break;
              case "site":
                this.deleteRequest={
                    type: "site",
                    charger_site_id: this.selectedChargerName.charger_site_id,
                    uniq_charger_id: this.selectedChargerName.unique_site_id,
                    startDate: this.deleteByDatetime.startDate,
                    endDate: this.deleteByDatetime.endDate,
                  }
                break;
              case "port":
                this.deleteRequest={
                  type: "port",
                  uniq_charger_id: this.selectedChargerName.unique_port_id,
                  startDate: this.deleteByDatetime.startDate,
                  endDate: this.deleteByDatetime.endDate,
                }
                break;
              default:
                break;
            }
            
         


            // console.log("this.filter", this.filter);
            return ElMessageBox.confirm(
                `Do you want to proceed with deleting sessions for ${this.selectedChargerName.charger_name}?`,
                "confirm",
                {
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  type: "Confirm Delete",
                }
              ).then(() => {
                this.submitDelete();
              }
              ).catch(() => {
                console.log("Delete cancelled");
              });
        },

        // submiteDelete
        /**
         * Submits the delete sessions request.
         *
         * Description:
         * - Displays an initial alert indicating that the delete request has been submitted.
         * - Sends a request to delete sessions by unique charger ID using the `deleteRequest` object.
         * - If the deletion is unsuccessful, displays an error alert with the result message.
         * - If the deletion is successful, displays a success alert with detailed information about the deletion.
         * - Resets the form and emits an `afterDelete` event upon successful deletion.
         * - Logs any errors that occur during the delete operation and displays an error alert.
         *
         * @returns {Promise<void>} - A promise that resolves when the delete operation is completed.
         */
        async submitDelete() {

          try {

            await ElMessageBox.alert(
              "Delete request submitted!",
              {
                confirmButtonText: "OK",
                type: "success",
              }
            );

            // console.log("submit delete");

            let result = null;

            const res = await userService.DeleteSessionsByUniqueChargerId(this.deleteRequest);
            result = res.data;
            // console.log("result", result);

            if (!result.success) {
              await ElMessageBox.alert(
                `${result.message}`,
                {
                  confirmButtonText: "OK",
                  type: "error",
                }
              );

            } else {
              await ElMessageBox.alert(
              `
                <p>${result.message}</p>
                <p>${result.type}</p>
                <p>Total sessions requested to delete: ${result.total_delete_request}</p>
                <p>Total sessions deleted: ${result.total_deleted_success}</p>
                <p>Sessions ID failed to delete: ${result.failed_to_delete}</p>
              `,
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );

            this.resetAll();
            this.$emit("afterDelete");
            
            }

          } catch (error) {
            console.error("Error during delete operation", error);
            ElMessageBox.alert(
              "An error occurred during the delete operation. Please try again.",
              {
                confirmButtonText: "OK",
                type: "error",
              }
            );
          }
        },

        // handleReset
        resetAll() {
            this.chargers = [];
            this.selectedCustomer.customer_id = null;
            this.selectedCustomer.full_name = null;
            this.selectedChargerName.charger_id = null;
            this.selectedChargerName.charger_name = null;

            this.deleteAll.uniq_charger_id = null;
            this.deleteByDatetime.uniq_charger_id = null;
            this.deleteByDatetime.startDate = null;
            this.deleteByDatetime.endDate = null;

            this.filter = {
              customer_id: null,
              state: "All",
              startDate: null,
              endDate: null,
              groupBy: null,
              groupByItem: null,
              charger_name: null,
            };

            this.fetchedTimePeriod = [];
            this.$emit("showchildDropdownTable", false);

            this.fetchMonitoringPeriod();
        },

        // handleSelectCustomer
        /**
         * Handles the selection of a customer.
         * @param {number} id - The ID of the selected customer.
         * @param {string} name - The full name of the selected customer.
         * Description:
         * - If the `id` is null, logs a message indicating that no customer ID was found.
         * - Sets the `customer_id` and `full_name` of `selectedCustomer` to the provided `id` and `name`.
         */
        async handleSelectCustomer(id, name) {
          if (id == null) {
            console.log("no customer id found");
          }
          this.selectedCustomer.customer_id = id;
          this.selectedCustomer.full_name = name;

        
          // console.log(this.selectedCustomer);

          let foundCharger = this.customer_chargers.find((item) => item.customer_id == id);
          if (!foundCharger) {
            // await this.fetchChargerData(id);
            await this.fetchAllInOneChargerData(id);
          }

          // fetch charger data to update charger list after selecting customer

        },


        // handleSelectCharger
        /**
         * Handles the selection of a charger and updates the selected charger information.
         *
         * Description:
         * - Resets and updates the selected charger information based on the provided parameters.
         * - Emits events to update the parent component with the selected charger information, table columns, and to show the child dropdown table.
         *
         * @param {string} charger_site_id - The ID of the charger site.
         * @param {string} site_name - The name of the site.
         * @param {string} charger_name - The name of the charger.
         * @param {string} charger_id - The ID of the charger.
         * @param {string} unique_port_id - The unique ID of the port.
         * @param {string} unique_site_id - The unique ID of the site.
         * @returns {Promise<void>} - A promise that resolves when the charger information is updated and events are emitted.
         */
        async handleSelectCharger(charger_site_id, site_name, charger_name, charger_id, unique_port_id, unique_site_id) {


          // charger
          // reset selected charger id
          this.selectedChargerName.charger_id = charger_id;
          // update required request data
          this.selectedChargerName.charger_site_id = charger_site_id;
          this.selectedChargerName.site_name = site_name;
          this.selectedChargerName.charger_name = charger_name;

          // port
          this.selectedChargerName.unique_port_id = unique_port_id;

          // site
          this.selectedChargerName.unique_site_id = unique_site_id;

          // console.log("selected charger", charger_site_id, site_name, charger_name, charger_id, unique_port_id, unique_site_id);

          this.$emit("selectedChargerInfo", this.selectedChargerInfo);
          this.$emit("tableColumns", this.tableColumns);
          this.$emit("showchildDropdownTable", true);
          

        },

        /**
         * Switches the charger dropdown data based on the selected event type.
         *
         * Description:
         * - Gets the selected customer ID.
         * - If the customer ID is not null:
         *   - Tries to switch the dropdown data based on the event type.
         *   - Updates the `chargers`, `chargers_dropdown`, `charger_sites_dropdown`, or `charger_ports_dropdown` arrays accordingly.
         * - Handles errors by logging them.
         * - If the customer ID is null, logs a message indicating that no customer ID was found and clears the `chargers` array.
         *
         * @param {string} event - The event type (e.g., "charger", "site", "port").
         * @returns {Promise<void>} - A promise that resolves when the dropdown data is switched.
         */
        async switchChargerDropdownData(event) {

          // get selected customer id
          let id = this.selectedCustomer.customer_id;

          if (id != null) {
            // this.chargers = [];

            try {

              switch (event) {

                case "charger":
                  this.chargers = this.customer_chargers.find((item) => item.customer_id == id).chargers;
                  this.chargers_dropdown = this.chargers.map((item) => {
                    return {
                      charger_id: item.charger_id,
                      charger_name: item.charger_name,
                      site_name: item.site_name,
                      operational_date: item.operational_date,
                    };
                  });
                  break;
                case "site":
                  this.chargers = this.customer_charger_sites.find((item) => item.customer_id == id).charger_sites;
                  this.charger_sites_dropdown = this.chargers.map((item) => {
                    return {
                      charger_site_id: item.charger_site_id,
                      unique_charger_id: item.unique_charger_id,
                      site_name: item.site_name,
                      state: item.state,
                      suburb: item.suburb,
                    };
                  });
                  // console.log("chargers", this.chargers);
                  
                  // console.log("charger_sites_dropdown", this.charger_sites_dropdown);
                  
                  break;
                case "port":
                  this.chargers = this.customer_charger_ports.find((item) => item.customer_id == id).charger_ports;
                  this.charger_ports_dropdown = this.chargers.map((item) => {
                    return {
                      unique_port_id: item.unique_port_id,
                      port_id: item.port_id,
                      site_name: item.site_name,
                      charger_name: item.charger_name,
                      operational_date: item.operational_date,
                    };
                  });
                  break;
                default:
                  break;
              }
              } catch (error) {
                console.log(error);
              }
            
        } else {
          console.log("no customer id found");
          this.chargers = [];
        }

        },



      // fetchMonitoringPeriod for selected start and end date dropdown
      /**
       * 
       * Fetches the current monitoring period and updates the filter and deleteByDatetime dates.
       *
       * Description:
       * - Sends a request to fetch monitoring periods.
       * - Filters the response data to find the 'current monitoring period'.
       * - Updates the `filter` and `deleteByDatetime` objects with the start and end dates of the current monitoring period, appending "00:00:00" to the start date and "23:59:59" to the end date.
       *
       */

       async fetchMonitoringPeriod() {

        try {
          const requestData = [];
        //   const monitoringPeriodsRes = {
        //     data: {
        //       monitoring_periods: [
        //             {
        //                 "id": 1,
        //                 "start_date": "2023-01-01",
        //                 "end_date": "2023-12-31",
        //                 "status": "current",
        //                 // "status_display": "Current Monitoring Period"
        //             },
        //             {
        //                 "id": 2,
        //                 "start_date": "2021-12-01",
        //                 "end_date": "2022-12-31",
        //                 "status": "under_audit",
        //                 "status_display": "Under Audit"
        //             },
        //             {
        //                 "id": 3,
        //                 "start_date": "2020-12-01",
        //                 "end_date": "2021-01-31",
        //                 "status": "revision",
        //                 // "status_display": "revision"
        //             }
        //         ],
        //     },
        // };

          const monitoringPeriodsRes = await userService.getMonitoringPeriods(null, "", 1, 50, true);

          // console.log("monitoringPeriodsRes", monitoringPeriodsRes);

          if (!monitoringPeriodsRes.data || !monitoringPeriodsRes.data.monitoring_periods) {
            throw new Error("No monitoring periods data found.");
          }

          requestData.push({
            "table_name": "monitoring_periods",
            "data": monitoringPeriodsRes.data.monitoring_periods,
          });
          // console.log("requestData", requestData);

          let filtData = requestData[0].data.filter((item) => item.status_display == "Current Monitoring Period");
          let filtDataTwo = requestData[0].data.filter((item) => item.status_display == "revision");

          let errorMessage = '';

          if (filtData.length === 0) {
            this.showCMP = false;
            errorMessage += "Current Monitoring Period not found. ";
          }

          if (filtDataTwo.length === 0) {
            this.showRMP = false;
            errorMessage += "Revision Monitoring Period not found.";
          }

          if (errorMessage) {
            console.log("error when fetching monitoring data", errorMessage);
          }

          if (filtData.length > 0) {
            this.showCMP = true;
            this.deleteByDatetime.startDate = filtData[0].start_date + " 00:00:00";
            this.deleteByDatetime.endDate = filtData[0].end_date + " 23:59:59";

            this.fetchedTimePeriod.push({
              "type": "current_mp",
              "start_date": filtData[0].start_date + " 00:00:00",
              "end_date": filtData[0].end_date + " 23:59:59",
            });
        }

        if (filtDataTwo.length > 0) {
            this.showRMP = true;
            this.fetchedTimePeriod.push({
              "type": "revision_mp",
              "start_date": filtDataTwo[0].start_date + " 00:00:00",
              "end_date": filtDataTwo[0].end_date + " 23:59:59",
            });
        }

        if (this.fetchedTimePeriod.length > 0) {
            this.filter.startDate = this.fetchedTimePeriod[0].start_date;
            this.filter.endDate = this.fetchedTimePeriod[0].end_date;
        }

          if(this.showCMP || this.showRMP) {
            this.showMP = true;
          } else {
            this.showMP = false; // switch if show monitoring period radio and time picker
          }
           
          // console.log("fetchedTimePeriod", this.fetchedTimePeriod);
        } catch (error) {
          console.error("Error fetching monitoring period:", error);
          ElMessageBox.alert(
            `
              <p>Error fetching monitoring period:</p>
              <p>${error.message}</p>
            `,
            {
              confirmButtonText: "OK",
              type: "error",
              dangerouslyUseHTMLString: true,
            }
          );

          // this.showMP = false; // switch if show monitoring period radio and time picker
        }
      },
      

      // fetchCustomerData for customer dropdown
      /**
       * Fetches the customer data.
       *
       * Description:
       * - Sends a request to fetch customer data.
       * - Updates the `customers` dropdown list with the response data.
       * - Logs any errors that occur during the fetch operation.
       * - Redirects to the login page if the response status is 401.
       */
      async fetchCustomerData() {
        await UserService.getCustomers(null)
          .then(
            (response) => {
              this.customers = response.data.customers;
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            }
          )
          .catch((error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          })
          .finally(() => (this.loading = false));
      },

    /**
     * Fetches all-in-one charger data for a given customer ID.
     *
     * Description:
     * - Sets `allInOneChargerData` to null.
     * - If the `id` is not null:
     *   - Sets `chargerLoading` to true.
     *   - Creates a request body with the customer ID.
     *   - Sends a request to fetch all-in-one charger data.
     *   - Updates `allInOneChargerData` with the response data.
     *   - Processes the data asynchronously.
     * - Handles errors by logging them and redirecting to the login page if the response status is 401.
     * - Ensures that `chargerLoading` is set to false after the request is completed.
     *
     * @param {number} id - The customer ID.
     * @returns {Promise<void>} - A promise that resolves when the charger data is fetched and processed.
     */
    async fetchAllInOneChargerData(id) {
      this.allInOneChargerData = null;
      if (id != null) {
        this.chargerLoading = true;
        let body = {
          customer_id: id,
        };

        try {
          const response = await UserService.GetThreeInOneChargerData(body);
          let data = response.data;

          this.allInOneChargerData = data;
          // Process data asynchronously
          // await this.processChargerData(id, data);

        } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ path: "/" });
          }
        } finally {
          this.chargerLoading = false;
          await this.processChargerData(id, this.allInOneChargerData);
        }
      }
    },


    /**
     * Processes the charger data for a given customer ID.
     *
     * Description:
     * - Extracts the charger, port, and site data from the response.
     * - Uses `setTimeout` to process data in chunks to avoid blocking the main thread.
     * - Pushes the charger, port, and site data to their respective arrays.
     * - Calls `switchChargerDropdownData` to update the dropdown based on the selected type.
     *
     * @param {number} id - The customer ID.
     * @param {Object} data - The data object containing chargers, charger_ports, and charger_sites.
     * @returns {Promise<void>} - A promise that resolves when the data is processed.
     */
    async processChargerData(id, data) {
      const filter_charger = data.chargers;
      const filter_port = data.charger_ports;
      const filter_site = data.charger_sites;

      // Use setTimeout to process data in chunks
      await new Promise((resolve) => {
        setTimeout(() => {
          this.customer_chargers.push({
            customer_id: id,
            chargers: filter_charger,
          });
          // console.log("chargers", this.customer_chargers);
          
          resolve();
        }, 0);
      });

      await new Promise((resolve) => {
        setTimeout(() => {
          this.customer_charger_ports.push({
            customer_id: id,
            charger_ports: filter_port,
          });
          // console.log("customer_charger_ports", this.customer_charger_ports);
          
          resolve();
        }, 0);
      });

      await new Promise((resolve) => {
        setTimeout(() => {
          this.customer_charger_sites.push({
            customer_id: id,
            charger_sites: filter_site,
          });
          // console.log("customer_charger_sites", this.customer_charger_sites);
          
          resolve();
        }, 0);
      });

      this.switchChargerDropdownData(this.selectedDeleteBy);
    },
      handleCustomDate() {
        // this.filter.startDate = event;
        // this.filter.endDate = event;
        this.$emit("filterHandle", this.filter);
      },
    },
  };
  </script>
  
  <style scoped>

  .el-radio-group {
    border: 1px solid #547477;
    border-radius: 8px;
  }
  .el-radio-group > .el-radio-button {
    padding: 4px;
    background-color: #0d3937;
    margin: 0;
  }
  
  .el-radio-group > .is-active > span {
    border-radius: 8px !important;
    width: 100% !important;
  }
  
  .el-radio-group > .el-radio-button > span {
    background-color: #0d3937;
    border: none !important;
    color: #ffff;
  }
  
  .el-dropdown-menu {
    background-color: #0d3937 !important;
    color: white !important;
  }
  
  .el-dropdown-menu__item {
    color: white !important;
  }
  
  .el-dropdown-menu__item:not(.is-disabled):focus {
    background-color: #547477 !important;
  }
  
  .label-color > label {
    color: white !important;
    width: 80px !important;
  }
  .label-color > div > div {
    height: 100% !important;
    display: flex !important;
    align-items: center;
  }
  
  .el-dropdown__list > .el-dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
  
  .el-radio-button__inner {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .option-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .option-header {
    position: sticky;
    top:0;
    z-index: 1;
    font-weight: bold;
    background-color: #f2f2f2;
  }

  .option-item {
    color: var(--el-text-color-secondary);
    font-size: 13px;
    flex: 1;
    text-align: center;
    padding: 4px;
  }

  .warning-message {
  color: orangered;
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
}

.radio-label {
  color: #ffffff;
  display: flex;
  align-items: center;
  height: 100%;
}





  </style>
  