<template>
    <el-col :span="$vuetify.display.width >= 700 ? 24 : 24" :class="$vuetify.display.width < 700 ? 'py-2 x-end' : 'height-100-p'">
        <div >
          <el-table 
            scrollbar-always-on 
            :data="tableData"
            class="my-2 data-table"
            style="width: auto;"
            >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.prop"
              :label="column.label"
              :prop="column.prop"
              :width="$vuetify.display.width <= 1400 ? 200 : 180"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-col>
   
  </template>
  
  <script>



  export default {
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        tableColumns: {
            type: Array,
            default: () => []
        },
    },
  };
  </script>
  