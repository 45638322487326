<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  
    <div class="container-fluid">
      <bread_crumbs main="Snapshot Reports" :breadcrumbPath="breadcrumbPath" />

      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center px-2" :span="24">
          <div class="row m-0 width-100-p px-2">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
              <el-col
              v-if="$vuetify.display.width < 748"
              class="p-0 pr-2"
              :span="18">
                <Filter
                  :stateProp="stateList"
                  @filterHandle="snapshotFilterHandle"
                  :hasCustomDate="true"
                  :hasCustomer="false"
                  :hasPeriod="false"
                  :periodNameProp="`2023 MP`"
                  />
              </el-col> 
        
              <el-col
              v-if="$vuetify.display.width < 748"
                class="p-0 pt-2"
               
                :span="6">
                  <el-button
                      v-if="
                         $store.state.auth.user != null &&
                         $store.state.auth.user.is_admin
                         "
                        @click="handleSnapshot"
                        color="#0D3937"
                        class="height-40 border-color-light rounded-3"
                        >
                      
                        <span class="me-primary-light">Snapshot</span>
                    </el-button>

              </el-col>

              <el-col
              v-if="$vuetify.display.width >= 748 && $vuetify.display.width < 1608"
              :span="20">
                <Filter
                  :stateProp="stateList"
                  @filterHandle="snapshotFilterHandle"
                  :hasCustomDate="true"
                  :hasCustomer="false"
                  :hasPeriod="false"
                  :periodNameProp="`2023 MP`"
                  />
              </el-col> 
        
              <el-col
              v-if="$vuetify.display.width >= 748 && $vuetify.display.width < 1608"
               class="mb-2"
                :span="4">
                  <el-button
                      v-if="
                         $store.state.auth.user != null &&
                         $store.state.auth.user.is_admin
                         "
                        @click="handleSnapshot"
                        color="#0D3937"
                        class="height-40 border-color-light rounded-3 mr-1"
                        >
                      
                        <span class="me-primary-light">Snapshot</span>
                    </el-button>

              </el-col>

              <el-col
              v-if="$vuetify.display.width >= 1608"
              :span="22">
                <Filter
                  :stateProp="stateList"
                  @filterHandle="snapshotFilterHandle"
                  :hasCustomDate="true"
                  :hasCustomer="false"
                  :hasPeriod="false"
                  :periodNameProp="`2023 MP`"
                  />
              </el-col> 
        
              <el-col
              v-if="$vuetify.display.width >= 1608"
               class="mb-2"
                :span="2">
                  <el-button
                      v-if="
                         $store.state.auth.user != null &&
                         $store.state.auth.user.is_admin
                         "
                        @click="handleSnapshot"
                        color="#0D3937"
                        class="height-40 border-color-light rounded-3 mr-1"
                        >
                      
                        <span class="me-primary-light">Snapshot</span>
                    </el-button>

              </el-col>
              
                
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center px-2" :span="24">
          <div class="row m-0 width-100-p px-2">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >

            <!-- width > 700 -->
              <el-col class="mb-2" :span="24">
                <div class="row m-0">

                  <el-col
                    v-if="$vuetify.display.width < 700"
                    :span="24"
                    class="d-flex align-item-center"
                    >
                    <el-input
                        size="large"
                        v-model="dataTableFilter.search"
                        placeholder="Type to search by user name ..."
                        :prefix-icon="Search"
                        class="table-search"
                        :class="$vuetify.display.width >= 375 ? 'width-200' : ''"
                      />
                    <el-button
                      color="#ddf0ac"
                      @click.stop="searchByCustomer()"
                      size="small"
                      class="height-40 width-40-p rounded-2 m-0 ml-2"
                    >
                      <i class="fa fa-refresh pr-2"></i>
                      <span class="me-primary"> Apply </span>
                    </el-button>
                
                  </el-col>

                  <!-- width > 700 -->
                  <el-col
                    v-if="$vuetify.display.width >= 700"
                    :span="$vuetify.display.width >= 1200 ? 14 : 14"
                    class="d-flex align-item-center"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center mt-2'
                        : 'justify-start'"
                    >
                      <el-input
                        size="large"
                        v-model="dataTableFilter.search"
                        placeholder="Type to search by user name ..."
                        :prefix-icon="Search"
                        class="table-search"
                        :class="$vuetify.display.width >= 1200 ? 'width-400' : ''"
                      />
                      <el-button
                        color="#ddf0ac"
                        @click.stop="searchByCustomer()"
                        size="small"
                        class="height-40 rounded-2 m-0 ml-2"
                      >
                        <i class="fa fa-refresh pr-2"></i>
                        <span class="me-primary"> Apply </span>
                      </el-button>
      
                  </el-col>

                  <!-- time picker -->
                  <el-col
                    v-if="$vuetify.display.width < 700"
                    :span="24"
                    class="d-flex align-item-center"
                    >

                    <el-date-picker
                        v-model="filter.logDateTime"
                        type="datetime"
                        placeholder="Filter table by log date and time"
                        size="default"
                        class="w-100"
                        :class="$vuetify.display.width >= 375 ? 'width-200 mt-2' : ''"
                        format="DD/MM/YYYY HH:mm:ss"
                        date-format="DD/MM/YYYY"
                        time-format="HH:mm:ss"
                        value-format="YYYY-MM-DD HH:mm:ss"
                      />
                
                  </el-col>

                  <!-- width > 700 -->
                  <el-col
                    v-if="$vuetify.display.width >= 700"
                    :span="$vuetify.display.width >= 1200 ? 10 : 10"
                    class="d-flex align-item-center"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center mt-2'
                        : 'justify-start'"
                    >

                      <el-date-picker
                        v-model="filter.logDateTime"
                        type="datetime"
                        placeholder="Filter table by log date and time"
                        size="default"
                        class="w-100"
                        :class="$vuetify.display.width >= 1200 ? 'width-400' : ''"
                        format="DD/MM/YYYY HH:mm:ss"
                        date-format="DD/MM/YYYY"
                        time-format="HH:mm:ss"
                        value-format="YYYY-MM-DD HH:mm:ss"
                      />

      
                  </el-col>
                   
                  
    
                </div>
              </el-col>
  
              <DataTable
                :loadingProp="loading"
                :usersDataProp="historyDataList"
                :totalRowProp="total_items"
                @tableAction="handleTableAction"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <ShowDetail
        :open_modal="show_modal"
        :data_modal="data_modal"
        :data_comment="data_comment"
        @close_modal="close_modal"
      />
    </div>
</template>
  
<script>
import bread_crumbs from "@/components/bread_crumbs.vue";
import XLSX from "xlsx";
import DataTable from "./dataTable.vue";

import ShowDetail from "./dialogDetail.vue";
import userService from "@/services/user.service";

// import filter
import Filter from "../common/filter.vue";

import { ElMessageBox } from "element-plus";

import moment from 'moment-timezone';




export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "snapshotReports",
    components: {
      
      ShowDetail,
      DataTable,
      bread_crumbs,

      Filter,
  },

    data() {
      return {

        

        // for send snapshotRequest
        // step 5
        snapshotRequest: {
          log_date: null,
          form_kind: "report_snapshot",
          action_kind: "create_snapshot",
          document_id: "report_snapshot",
          comment: "snapshot of the reports",
          before_change : [],
      },
      // for send snapshotRequest

        breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Snapshot Reports",
          disabled: false,
          href: "/dashboard-snaphot-reports",
          active: true,
        },
      ],
      
        show: true,
        historyDataList: [],
        originalHistoryDataList: [], // use for reset data
        current_page: 1,
        page_size: 50,
        total_items: 0,
        perPageCount: 50,
        loading: false,
        show_modal: false,
        data_modal: null,

        search: "",

        // comment for snapshot report data : send to child component dialogDetail.vue
        data_comment: null,

        current_row_index: null,
        filterOpen: false,

        dataTableFilter: {
          search: "",  // search by user name
        },

        filter: {
          fromDate: "",
          toDate: "",
          formKind: "",
          actionKind: "",
          search: "", 

          logDateTime: "",
        },

        auditorReportRequest: {
          start_date: null,
          end_date: null,
          search: null,
          report_type : null,
          current_page: null,
          perPageCount: null,
          no_page: true,
        },

        requestOthers: {
          start_date: null,
          end_date: null,
          search: null,
          current_page: null,
          perPageCount: null,
        },

        requestChargers: {
          customer_id: null,
          start_date: null,
          end_date: null,
          search: "",
          state: "",
        },


        // snapshot request filter
        snapshotFilter: {
          // customer_id: null,
          startDate: null,
          endDate: null,
          search: null, // Search bar

        },


      };
    },
    watch: {
      'filter.logDateTime': function () {
        // console.log("logDateTime", newVal);
        this.filterHistoryData();
      },
    },
    computed: {
      filter_history() {
        return this.historyDataList;
      },

      // stateList to filter
      stateList() {
        return this.states;
    },

    },
    mounted() {
    // snapshot request
    // step 4
    // reset before_change data to empty for snapshot request
    this.snapshotRequest.before_change = [];
    // snapshot request

      this.fetchHistoryData(this.current_page);
    },
    methods: {

      /**
     * Recursively converts all values in an object to strings.
     *
     * @param {Object} obj - The object to convert.
     * @returns {Object} - The object with all values converted to strings.
     */
    //  convertValuesToString(obj) {
    //   if (obj === null) return "null";
    //   if (typeof obj === "boolean") return obj.toString();
    //   if (typeof obj === "object") {
    //     for (const key in obj) {
    //       if (Object.prototype.hasOwnProperty.call(obj, key)) {
    //         obj[key] = this.convertValuesToString(obj[key]);
    //       }
    //     }
    //   }
    //   return obj;
    // },
    convertValuesToString(obj) {
      if (obj === null) return "null";
      if (typeof obj === "boolean") return obj.toString();
      if (typeof obj === "object") {
        const result = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if ((key === 'address_comment' || key === 'city' || key === 'suburb' || key === 'site_name')  && typeof obj[key] === 'string') {
              // Replace single quotes with '&' and convert to JSON string
              result[key] = obj[key].replace(/'/g, '$@$');
            } else {
              result[key] = this.convertValuesToString(obj[key]);
            }
          }
        }
        return result;
      }
      return obj.toString();
    },


    // Handle filter snapshot log table by time picker; date or date and time
    filterHistoryData() {

      if(this.filter.logDateTime == null || this.filter.logDateTime == "") {
        this.historyDataList = [...this.originalHistoryDataList];
        return;
      } else {
        this.historyDataList = [...this.originalHistoryDataList.filter(item => this.convertTimezone(item.log_date,'date_time').includes(this.filter.logDateTime.replace('00:00:00', '')))];
      }
      
    },

    /** convert response data from UTC to AU/SYD 
     * Converts a given date string to a specified format in the 'Australia/Sydney' timezone.
     *
     * @param {string} dateString - The date string to be converted. Expected format: 'YYYY-MM-DDTHH:mm:ss.sssZ'.
     * @param {string} type - The type of format to return. Can be 'date', 'time', or 'date_time'.
     * @returns {string} - The formatted date string in the 'Australia/Sydney' timezone.
     *
     * Description:
     * - The function first cleans the input date string by removing any fractional seconds and appending 'Z' to indicate UTC time.
     * - It then creates a moment object in UTC and converts it to the 'Australia/Sydney' timezone.
     * - Depending on the 'type' parameter, the function returns the date in one of the following formats:
     *   - 'date': 'YYYY-MM-DD'
     *   - 'time': 'HH:mm:ss'
     *   - 'date_time': 'YYYY-MM-DD HH:mm:ss'
     */
    convertTimezone(dateString, type) {
        const cleanedDateString = dateString.split('.')[0] + 'Z';
        const date = moment.utc(cleanedDateString).tz('Australia/Sydney');
        if (type === 'date') {
          return date.format('YYYY-MM-DD');
        } else if (type === 'time') {
          return date.format('HH:mm:ss');
        }
        if (type === 'date_time') {
          return date.format('YYYY-MM-DD HH:mm:ss');
        }

    },


      /**
       * Handles the snapshot request process.
       * 
       * Description:
       * - Displays an alert indicating that the snapshot request has been sent.
       * - Resets the `before_change` data to an empty array.
       * - Defines an array of snapshot functions to fetch data for various reports.
       * - Iterates over the snapshot functions, executing each one and collecting the results.
       * - Displays a summary message indicating the success or failure of each snapshot function.
       * - Sends the snapshot request to the server with the collected `before_change` data.
       * - Refreshes the history data.
       */
      async handleSnapshot() {

        ElMessageBox.alert("Snapshot request sent!", { dangerouslyUseHTMLString: true });

        // reset before_change data to empty for snapshot request
        this.snapshotRequest.before_change = [];

        const results = [];

        // Define the snapshot functions
        const snapshotFunctions = [
          { func: this.shotAuditor, name: "Auditor Report" },
          { func: this.shotVerra, name: "Verra Report" },
          { func: this.shotChargers, name: "Chargers" },
          { func: this.shotBaseline, name: "Baseline Params" },
          { func: this.shotMonitoring, name: "Monitoring Report" },
          { func: this.shotBatteryIntensities, name: "Battery Intensities" },
          { func: this.shotUserTable, name: "User Table" },
        ];

        for (const snapshot of snapshotFunctions) {
          try {
            const response = await snapshot.func();
        
            results.push({ name: snapshot.name, success: response.success, message: response.message });
          } catch (error) {
            results.push({ name: snapshot.name, success: false, message: error.message });
          }
        }

        // Show a single alert message summarizing the results
        const successMessages = results.filter(result => result.success).map(result => `${result.name}: Success`).join('<br/>');
        const failureMessages = results.filter(result => !result.success).map(result => `${result.name}: Failed - ${result.message}`).join('<br/>');

        const summaryMessage = `
          <strong>Snapshot Request Summary:</strong><br/>
          ${successMessages}<br/>
          ${failureMessages}
        `;

        // console.log("before request", this.snapshotRequest.before_change);
        
        await this.sendSnapshot("Snapshot of Reports", this.snapshotRequest.before_change);

        await ElMessageBox.alert(summaryMessage, 
        {                 
          confirmButtonText: "OK",
          type: "success",
          dangerouslyUseHTMLString: true,
        });

        this.fetchHistoryData(this.current_page); // Refresh the history data

      },
          
      /**
       * Sends a snapshot request to the server.
       *
       * @param {string} reqComment - The comment describing the snapshot.
       * @param {Array} reqData - The data before the change.
       * @returns {Promise<Object>} - A promise that resolves to the response from the server.
       *
       * Description:
       * - Constructs a `snapshotRequest` object with the provided comment and data.
       * - Sets the current date and time as the `log_date`.
       * - Sends the snapshot request to the server using the `userService.SnapshotReport` method.
       * - Logs any errors that occur during the request.
       * - Resets the `before_change` data after sending the request.
       */
      async sendSnapshot(reqComment, reqData) {

          // const before_change = this.snapshotRequest.before_change;
          const snapshotRequest = this.snapshotRequest;

          snapshotRequest.comment = reqComment;
          snapshotRequest.before_change = reqData;

            try {
                // console.log("request", snapshotRequest);

                const currentDate = new Date().toISOString();
                snapshotRequest.log_date = currentDate;

                const snapshotRes = await userService.SnapshotReport(snapshotRequest);
                // console.log("before_change", before_change);
                // console.log('test snapshot', snapshotRes);
                return snapshotRes;
                } catch (error) {

                  console.error("Error when sending snapshot request", error);
              }
            
              // reset before_change data
              snapshotRequest.before_change = [];

          },
          // handle snapshot button

      // add before_change data to snapshot request

      // auditor report
      async shotAuditor() {

        try {
          
          const requestData = [];

          const report_type = [
                  "client_total",
                  "full_breakdown",
                  "by_state_and_region",
                  "by_state_and_region_and_charger_type",
                  "verra_template",
                  "private_fleet_by_site",
                  "by_state_and_region_1",
                  "by_state_and_region_2",
                  "sd_vista",
          ];

          for (const type of report_type) {

            this.auditorReportRequest.report_type = type;

            // console.log("auditorReportRequest", this.auditorReportRequest);
            
            const res = await userService.DashboardAuditorReports(this.auditorReportRequest);

            const data = res.data.data.map(item => {
              return this.convertValuesToString(item);
            });

            requestData.push({
              "table_name": type,
              // "data": res.data.data,
              "data": data,
            });
          }

          // return this.sendSnapshot("Snapshot of Auditor Report", requestData); 
          this.snapshotRequest.before_change.push({"table_name": "auditor_report", "data": requestData});
          return { success: true, message: "success" };

      } catch (error) {
        console.error("Error when fetching before_change data for auditorReport", error);
      } 
      
      
      // auditor report
      },

      async shotVerra(){
        // verra Report start
      try {

        const requestData = [];
        const verra_report_type = [
                  "verra_eq1",
                  "verra_eq4",
                  "verra_eq5",
                  "verra_eq7",
                  "verra_eq9",
          ];

        // projectInstance
        const projectInstanceRes = await userService.getProjectInstance(null, null, 1, 50, true);

        const instance_data = projectInstanceRes.data.project_instance.map(item => {
          return this.convertValuesToString(item);
        });

          requestData.push({
            "table_name": "project_instance",
            // "data": projectInstanceRes.data.project_instance,
            "data": instance_data,
          });
          
        for (const type of verra_report_type) {

          this.auditorReportRequest.report_type = type;

          const VerraRes = await userService.DashboardAuditorReports(this.auditorReportRequest);

          const data = VerraRes.data.data.map(item => {
            return this.convertValuesToString(item);
          });

          requestData.push({
                "table_name": type,
                // "data": VerraRes.data.data,
                "data": data,
            });
        }

        // return this.sendSnapshot("Snapshot of Verra Report", requestData);
        this.snapshotRequest.before_change.push({"table_name": "verra_report", "data": requestData});
        return { success: true, message: "success" };
      } catch (error) {
        console.error("Error when fetching before_change data for verra Report", error);
      } 
      
      },
      // verra Report end

      // chargers
      async shotChargers(){
          // verra Report start
      try {

        const requestData = [];
        
        try {

          const chargerSitesRes = await userService.GetAllChargingSite(this.requestChargers, 1, 50, true);

          const chargerSitesData = chargerSitesRes.data.charging_sites.map(item => {
            return this.convertValuesToString(item);
          });

          requestData.push({
            "table_name": "charging_sites",
            // "data": chargerSitesRes.data.charging_sites,
            "data": chargerSitesData,
            "total_record": chargerSitesRes.data.total_record,
          });

          // console.log("charging_sites", chargerSitesData);
          

          const allChargersRes = await userService.GetAllCharger(this.requestChargers, 1, 50, true);
          const allChargersData = allChargersRes.data.chargers.map(item => {
            return this.convertValuesToString(item);
          });
          requestData.push({
            "table_name": "all_chargers",
            // "data": allChargersRes.data.chargers,
            "data": allChargersData,
            "total_record": allChargersRes.data.total_record,
          });

          const allPortsRes = await userService.GetAllPort(this.requestChargers, 1, 50, true);
          const allPortsData = allPortsRes.data.charger_ports.map(item => {
            return this.convertValuesToString(item);
          });
          requestData.push({
            "table_name": "charger_ports",
            // "data": allPortsRes.data.charger_ports,
            "data": allPortsData,
            "total_record": allPortsRes.data.total_record,
          });

        } catch (error) {
          console.error("Error when fetching before_change data for Chargers", error);
        }

        // return this.sendSnapshot("Snapshot of Chargers Report", requestData);
        this.snapshotRequest.before_change.push({"table_name": "charger", "data": requestData});
        return { success: true, message: "success" };
        } catch (error) {
        console.error("Error when send snapshot request for chargers", error);
        } 
      },
      // chargers end

      // baseline params
      async shotBaseline() {
        try {

          const requestData = [];

          try {

            const baselineParmsRes = await userService.getBaselineParams(null, this.search, 1, 50, true);
            const baselineData = baselineParmsRes.data.baseline_params.map(item => {
              return this.convertValuesToString(item);
            });
            requestData.push({
              "table_name": "baseline_params",
              // "data": baselineParmsRes.data.baseline_params,
              "data": baselineData,
            });

            const siteBaselineParmsRes = await userService.getSiteBaselineParams(null, this.search, 1, 50, true);
            const siteBaselineData = siteBaselineParmsRes.data.site_baseline_params.map(item => {
              return this.convertValuesToString(item);
            });
            requestData.push({
              "table_name": "site_Baseline_params",
              // "data": siteBaselineParmsRes.data.site_baseline_params,
              "data": siteBaselineData,
            });

          } catch (error) {
            console.error("Error when fetching before_change data for Baseline params", error);
          }

          // return this.sendSnapshot("Snapshot of Baseline Params Report", requestData);
          this.snapshotRequest.before_change.push({"table_name": "baseline_params", "data": requestData});
          return { success: true, message: "success" };
          } catch (error) {
          console.error("Error when send snapshot request for Baseline params", error);
          } 
      },
  
      // monitoring periods
      async shotMonitoring() {
        try {

          const requestData = [];

          try {

            const monitoringPeriodsRes = await userService.getMonitoringPeriods(null, this.search, 1, 50, true);
            const monitoringPeriodsData = monitoringPeriodsRes.data.monitoring_periods.map(item => {
              return this.convertValuesToString(item);
            });
            requestData.push({
              "table_name": "monitoring_periods",
              // "data": monitoringPeriodsRes.data.monitoring_periods,
              "data": monitoringPeriodsData,
            });

          } catch (error) {
            console.error("Error when fetching before_change data for monitoring periods", error);
          }

          // return this.sendSnapshot("Snapshot of Monitoring Periods", requestData);
          this.snapshotRequest.before_change.push({"table_name": "monitoring_periods", "data": requestData});
          return { success: true, message: "success" };
          } catch (error) {
          console.error("Error when send snapshot request for monitoring periods", error);
          } 
      },
      // monitoring periods end

      // Battery Intensities
      async shotBatteryIntensities() {
        try {

          const requestData = [];

          try {

            const batteryIntensitiesRes = await userService.getBatteryIntensities(null, this.search, 1, 50, true);
            const batteryIntensitiesData = batteryIntensitiesRes.data.battery_intensities.map(item => {
              return this.convertValuesToString(item);
            });
            requestData.push({
              "table_name": "battery_intensities",
              // "data": batteryIntensitiesRes.data.battery_intensities,
              "data": batteryIntensitiesData,
            });

          } catch (error) {
            console.error("Error when fetching before_change data for Battery Intensities", error);
          }

          // return this.sendSnapshot("Snapshot of Battery Intensities", requestData);
          this.snapshotRequest.before_change.push({"table_name": "battery_intensities", "data": requestData});
          return { success: true, message: "success" };
          } catch (error) {
          console.error("Error when send snapshot request for Battery Intensities", error);
          } 
      },
      //Battery Intensities end

      // User Table
      async shotUserTable() {
        try {

          const requestData = [];

          try {

            const userTableRes = await userService.getUsers(null, this.search, 1, 50, true);
            const data = userTableRes.data.users.map(user => {
              return this.convertValuesToString(user);
            })
            requestData.push({
              "table_name": "user_table",
              "data": data,
            });

          } catch (error) {
            console.error("Error when fetching before_change data for User Table Report", error);
          }

          // return this.sendSnapshot("Snapshot of User Table", requestData);
          this.snapshotRequest.before_change.push({"table_name": "user_table", "data": requestData});
          // console.log("before_change", this.snapshotRequest.before_change);
          
          return { success: true, message: "success" };
          } catch (error) {
          console.error("Error when send snapshot request for User Table Report", error);
          } 
      },
      //User Table end

      // add before_change data to snapshot request


      // snapshot filter handle
      snapshotFilterHandle(event) {
        this.snapshotFilter = event;
        this.handleApplySnapshotFilterData();
        return ElMessageBox.alert(`<strong>Snapshot filter data applied</strong> <br/> <strong>Please click on snapshot button to take snapshot</strong>`, { dangerouslyUseHTMLString: true });
      },

      handleApplySnapshotFilterData() {
        // console.log("handle snapshot filter data");
        // this.$refs.childComp.emitToParent(this.snapshotFilter);

        // update request data with filter data
        this.auditorReportRequest.start_date = this.snapshotFilter.startDate;
        this.auditorReportRequest.end_date = this.snapshotFilter.endDate;
        this.auditorReportRequest.search = this.snapshotFilter.search;

        this.requestOthers.search = this.snapshotFilter.search;
      },

      // fetch history data
      /**
       * Fetches the history data for snapshots.
       * 
       * @param {number} page - The page number to retrieve.
       * 
       * Description:
       * - Calls the `getSnapshotList` method from the `userService` with the provided page number, filter, and per page count.
       * - Processes the response to filter and update the `historyDataList` with items that have `form_kind` as "report_snapshot" and `action_kind` as "create_snapshot".
       * - Updates the `total_items` with the total record count from the response.
       * - Handles errors by logging them and redirecting to the home page if the error status is 401 (Unauthorized).
       * - Ensures that the loading state is set to false after the request is completed.
       */
      fetchHistoryData(page, filter) {
        // this.filter.search = this.search;
        userService.getSnapshotList(page, filter, this.perPageCount)
          .then(
            (response) => {
              const dataList = response.data.history_logs;

              if(dataList && dataList.length > 0) {
                this.historyDataList = dataList.filter((item) => item.form_kind === "report_snapshot" && item.action_kind === "create_snapshot");
                this.originalHistoryDataList = [...this.historyDataList];
              } else {
                this.historyDataList = [];
                this.originalHistoryDataList = [];
              }
              // this.historyDataList = dataList.filter((item) => item.form_kind === "report_snapshot" && item.action_kind === "create_snapshot");
              // this.historyDataList = response.data.history_logs;

              // const comments = this.historyDataList.map(item => ({comment: item.comment}));
              // console.table(comments);

              this.total_items = response.data.total_record;
              // console.log('total_items', response.data.total_record);
              // console.log('snap data', this.historyDataList.length);
              
              
      
            },
            (error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            }
          )
          .catch((error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          })
          .finally(() => (this.loading = false));
      },
  
   
      // handleExportFile(index, data) {
      //   if (data.before_change != null && data.before_change != "") {

      //     this.current_row_index = index;

      //     const fileName = data.comment;

      //     const fileData = JSON.parse(data.before_change.replaceAll("'", '"').replaceAll("None", "null").replaceAll("True", "true").replaceAll("False", "false"));

      //     // console.log("fileData", fileData);
      //     // console.log("fileName", fileName);

      //     const wb = XLSX.utils.book_new();

      //     for (const table of fileData) {
      //       const ws = XLSX.utils.json_to_sheet(table.data);
      //       XLSX.utils.book_append_sheet(wb, ws, (table.table_name).replace("by_state_and_region_and_charger_type", "byStateAndRegionAndChargerType")); 
      //       // if sheet name exceeds 31 characters replace it with shorter name
      //     }

      //     XLSX.writeFileXLSX(wb, `${fileName}.xlsx`);

      //   }
      // },

      /**
       * Exports the snapshot data to an Excel file.
       *
       * @param {number} index - The index of the current row.
       * @param {Object} data - The data object containing the snapshot details.
       *
       * Description:
       * - Checks if the `before_change` data is not null or empty.
       * - Sets the `current_row_index` to the provided index.
       * - Extracts the file name from the `comment` field of the data object.
       * - Parses the `before_change` data, replacing certain values to ensure proper JSON format.
       * - Creates a new workbook using `XLSX.utils.book_new()`.
       * - Iterates over the parsed `fileData` and appends each table's data as a separate sheet in the workbook.
       * - Writes the workbook to an Excel file with the specified file name.
       */
      handleExportFile(index, data) {
        if (data.before_change != null && data.before_change != "") {

          this.current_row_index = index;

          const fileName = data.comment;

          // console.log("excel: data before change", data.before_change);
          

          // const fileData = JSON.parse(data.before_change.replaceAll("'", '"').replaceAll("None", "null").replaceAll("True", "true").replaceAll("False", "false"));
          const fileData = JSON.parse(data.before_change.replaceAll("'", '"').replaceAll("$@$", "'"));
          // console.log("fileData", fileData);
          
        
          const wb = XLSX.utils.book_new();

          fileData.forEach((item) => {
          
            for (const table of item.data) {
              const ws = XLSX.utils.json_to_sheet(table.data);
              XLSX.utils.book_append_sheet(wb, ws, (table.table_name).replace("by_state_and_region_and_charger_type", "byStateAndRegionAndChargerType")); 
            // if sheet name exceeds 31 characters replace it with shorter name
          }
          });
          
          XLSX.writeFileXLSX(wb, `${fileName}.xlsx`);

        }
      },

      reset_filter11(event) {
        this.current_page = 1;
        this.search = "";
        this.filter = event.filter;
        this.fetchHistoryData(this.current_page);
      },
  
      handleTableAction(event) {
        if (event.kind == "edit") {
          this.handleShow(event.index, event.data);
        } else if (event.kind == "export") {
          this.handleExportFile(event.index, event.data);
        } else if (event.kind == "delete") {
          this.deleteRow(event.data, event.index);
        } else if (event.kind == "changePage") {
          this.current_page = event.index;
          this.fetchHistoryData(this.current_page);
        } else if (event.kind == "perPageChange") {
          this.current_page = 1;
          this.perPageCount = event.index;
          this.handleReload();
        }
      },
  
      handleReload() {
        // this.filter.search = event.filter.search;
        // this.filter = event.filter;
        this.fetchHistoryData(this.current_page);
      },

      searchByCustomer(){
        let name = this.dataTableFilter.search;
        if(name && name!="") {
          this.fetchHistoryData(this.current_page, this.dataTableFilter);
        }
        if(name == "") {
          this.fetchHistoryData(this.current_page);
        }
      },


      filterSearch11(event) {
        this.filter.search = event.search;
        this.fetchHistoryData(this.current_page);
      },
      handleShow(index, data) {
        try {
          // console.log(" send to data", data);

        
          if (data.before_change != null && data.before_change != "") {
            this.current_row_index = index;

            // get comment data for the row
            this.data_comment = data.comment;
            // send row data to popup window

            // console.log("data comment", data.commen);
            

            // console.log("data before change", data.before_change);
            // console.log("data before change", data.before_change.replaceAll("'", '"').replaceAll("$@$", "'"));
            
            // important: replace single quote to double quote for JSON.parse
            // important: replace None to null, True to true, False to false in JSON string
            // this.data_modal = JSON.parse(data.before_change.replaceAll("'", '"').replaceAll("None", "null").replaceAll("True", "true").replaceAll("False", "false"));
            this.data_modal = JSON.parse(data.before_change.replaceAll("'", '"').replaceAll("$@$", "'"));
            // console.log("data modal", this.data_modal);
            

            this.show_modal = true;

          }
        } catch (error) {
          console.error("Error when showing detail, if get error message: expected property name or '}', check data if in json format", error);
        }

  
       
      },
      // eslint-disable-next-line no-unused-vars
      close_modal(event) {
        this.show_modal = false;
      },
    },
  };
</script>
  
<style scoped>
  .el-table .warning-row {
    --el-table-tr-bg-color: var(--el-color-warning-light-8);
  }
  .el-table .error-row {
    --el-table-tr-bg-color: var(--el-color-error-light-8);
  }
  
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  
  .cell {
    text-align: center !important;
  }
  
  .starter-main .card-body ul {
    list-style-type: none !important;
  }
  
  .el-pager {
    -webkit-user-select: none !important;
    user-select: none !important;
    list-style: none !important;
    font-size: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }
  
  .el-upload-list {
    margin: 10px 0 0 !important;
    padding: 0 !important;
    list-style: none !important;
    position: relative !important;
  }
</style>
  