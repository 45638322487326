<template>
    <div>
        <Vue3Html2pdf 
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :pdf-quality="1"
        :manual-pagination="true"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a2"
        pdf-orientation="landscape"
        pdf-content-width='100%'
        @beforeDownload="beforeDownload($event)"
        ref="html2Pdf">
            <template #pdf-content>
                <!-- <Breadcrumbs main="Estimator" title="default" /> -->
                <div class="bg-me-primary border-color-light width-100-p">
                    <estimaOutput class="output" :moreScrenarios="pdfMoreScrenarios" @generateReport="generateReport" />
                </div>
            </template>
        </Vue3Html2pdf>
    </div> 

</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import estimaOutput from './estimaOutput.vue';

export default {

    computed: {
    },
    data(){
        return {

            htmlToPdfOptions: {
                filename:"Estimator-Output",
                
                html2canvas: {
                    scale: 1,
                    useCORS: false,
                    removeContainer: true,
                    foreignObjectRendering: true, // fixed issue with incorrect legend text color
                    imageTimeout: 0,
                   

                },

                jsPDF: {
                    unit: 'in',
                    format: 'a2',
                    orientation: 'landscape'
                },
            }
        }
    },

    props:{
        pdfMoreScrenarios: {
            type: Boolean,
        },
    },
    components: {
        Vue3Html2pdf,
        estimaOutput,
    },
    methods: {


        async beforeDownload({html2pdf, options, pdfContent }){
            console.log("Before download event", html2pdf, options, pdfContent);
            

            try {

                await this.$nextTick();

                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                
                    let totalPages = pdf.internal.getNumberOfPages();

                    // delete the last page of the pdf to remove two buttons
                    pdf.deletePage(totalPages);
                    
                }).save();

                console.log("single scenario PDF  save successfully");

                this.removeFixedWidth();

                

            } catch (error) {
                console.error("Error during single scenario PDF generation:", error);
            }
            
            
        },

        async generateReport() {

            try {

                await this.$refs.html2Pdf.generatePdf();

                console.log("single scenario PDF generated successfully");

            } catch(error) {
                console.error(error);
            }
            
        },
    },
 

}



</script>

<style scoped>
span{
    color: white;
}

.bg-me-primary {
  width: 2160px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.output {
  width: 2160px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.output * {
  box-sizing: border-box;
  width: 2160px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.width-100-p {
  width: 2160px !important;
}

/* .fixed-width .bg-me-primary,
.fixed-width .output,
.fixed-width .output * {
  width: 1700px !important; 
} */

</style>

