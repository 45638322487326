<template>
  <div>
    <el-row class="m-0 width-100-p" gutter="20">
      <el-col
        :span="$vuetify.display.width >= 1618 ? 20 : ($vuetify.display.width >= 1187 ? 20 : ($vuetify.display.width >= 721 ? 20 : 24))"
        :class="$vuetify.display.width >= 500 ? 'pl-2' : 'ml-3 mt-3 width-100-p'"
        v-if="hasLocation"
      >
        <!-- Location Dropdown (Country -> States) -->
        <el-dropdown ref="estimDropdown" visible-change="trueOrfalse" trigger="click" class="filter-drop width-100-p" @command="handleFilter" :hide-on-click="false" >
          <el-button
            color="#0D3937"
            class="height-40  border-color-light rounded-3 width-100-p ml-1"
            :class="{'bg-me-primary': isOdd(scenarioId), 'bg-me-primary-1': isEven(scenarioId)}"
          >
            <svg class="stroke-icon width-20 height-20">
              <use href="@/assets/svg/icon-sprite.svg#stroke-filter-location"></use>
            </svg>
            <span class="text-white pl-3">
              <!-- Filter by {{ selectedCountry ? selectedCountry : (selectedState ? selectedState : "Location") }} -->
              Calculate by {{ selectedState ? selectedState : "Location" }}
            </span>
          </el-button>

          <!-- Dropdown Menu -->
          <template #dropdown>
            <el-dropdown-menu class="border-color-light" :style="{ width: '206.88px', backgroundColor: '#FFFFFF' }"
            :class="{'bg-me-primary': isOdd(scenarioId), 'bg-me-primary-1': isEven(scenarioId)}">

              <!-- If no country selected, filter countries and show -->
              <template v-if="!selectedCountry">
                <el-dropdown-item
                  v-for="(location, index) in states"
                  :key="index"
                  @click="selectCountry(location.country)"
                >
                  {{ location.country }}
                </el-dropdown-item>
              </template>

              <!-- If a country is selected, filter states and show -->
              <template v-if="selectedCountry">
                <el-dropdown-item
                  v-for="(region, regionIndex) in selectedStates"
                  :key="regionIndex"
                  @click="selectState(region)"
                >
                  {{ region }}
                </el-dropdown-item>

                <!-- Back button to go back to country selection -->
                <el-dropdown-item @click="resetCountry">
                  <i>Back to Countries</i>
                </el-dropdown-item>
              </template>
              
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default{
  props: {
    applyFilter: {
      type: Function,
    },

    // receive scenarioID from index component scenarioid
    scenarioId: {
      type: Number,
      required: true
    },

  },
  emits:['updateForm'],

    data(){
        return{

            // date for filter by location
            states: [ 
                {country: "Australia", states: ["NSW", "QLD", "VIC", "SA", "WA", "TAS"]},
                {country: "UK", states: ["North Scotland", "South Scotland", "North West England", "North East England", "Yorkshire", "North Wales and Merseyside", "West Midlands", "East Midlands", "East England", "South West England", "South England", "London", "South East England", "Ireland"]},
                    ],

            selectedCountry: "", // Track the selected country
            selectedStates: [],  // Store the states of the selected country
            selectedState: "",   // Track the selected state
            hasLocation: true,
            // date for filter by location

            // data form send to input form
            form: {
              "country" : this.selectCountry,
              "region" : this.selectedState,
            },

        }
    },
    methods: {
        // check scenario id is even or odd
      isEven(id) {
          return id % 2 === 0;
        },

      isOdd(id) {
          return id % 2 !== 0;
        },
        // check scenario id is even or odd

      convertUKStates(states) {
        const newStates = states.toLowerCase().replace(/ /g, "_");
        return newStates;
      },
      convertAUStates(states) {
        const newStates = states.toLowerCase();
        return newStates;
      },

        selectCountry(country) {
        const location = this.states.find((loc) => loc.country === country);
        this.selectedCountry = country;
        this.selectedStates = location.states; // Update with selected country's states
    },

        selectState(state) {
        this.selectedState = state; // Set the selected state
        // console.log(`Selected Country: ${this.selectedCountry}`);
        // console.log(`Selected State: ${state}`); // Handle state selection logic
        this.handleVisible2(); // Close the dropdown after selecting a state
        if(this.selectedCountry === 'UK') {
          this.$emit('updateFilter',{'country': this.selectedCountry, 'region' : this.convertUKStates(state), 'regionBeforeConvert' : this.selectedState});
          this.handleApplyFilter();
        } else if (this.selectedCountry === 'Australia') {
          this.$emit('updateFilter',{'country': this.selectedCountry, 'region' : this.convertAUStates(state), 'regionBeforeConvert' : this.selectedState});
          this.handleApplyFilter();
        }
    },
        resetCountry() {
        this.selectedCountry = ""; // Reset country and go back to country selection
        this.selectedStates = [];
    },
        handleVisible2() {
            this.$refs.estimDropdown.handleClose(); // close filter list after state is choosen
            },

        handleApplyFilter() {
          this.applyFilter('location');
        },
    },
    mounted(){
        
      // active handleclose();
        this.$nextTick(() => {
       
        if (this.$refs.estimDropdown) {
           
            // console.log("Dropdown is mounted and ready");
         
            this.$refs.estimDropdown.handleClose();
        }
        });

        

    }


}

</script>

<style scoped>

h2 {
    color: white;
}
span {
    color: white;
}
label{
    color: white;
    font-size:medium;
}
.checkbox{
    margin-right: 11.17px;
    width: 16px;
    height: 16px;
}


</style>